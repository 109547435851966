import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {differenceBy, orderBy, sortBy, uniqBy} from 'lodash-es';
import {
  Box,
  Button,
  Checkbox,
  Cluster,
  Click,
  Cover,
  Dialog,
  Divider,
  Drawer,
  Expand,
  FormItem,
  Grid,
  Heading,
  Icon,
  ISelectOption,
  Label,
  Notification,
  Pagination,
  Paragraph,
  RadioGroup,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  Textarea,
  TextInput,
  Toast,
  trimModel,
  useValidateForm,
  ITableCol,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import channelRoutes from 'routes/programming.routes';
import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';
import {
  useFindQuery as useFindChannelQuery,
  useInsertMutation,
  useUpdateMutation,
  useBulkUpdateMutation,
} from 'features/channels/channelsApi';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import {useFindQuery as useFindLegacyCategoriesQuery} from 'features/channelCategories/channelCategoriesApi';
import {useFindQuery as useFindDevicesQuery} from 'features/devices/devicesApi';
import {IMainCategory} from 'models/mainCategories';
import {IChannel} from 'models/channels';
import {
  channelSearchValidator,
  channelCreateValidator,
  channelDeviceUpdateValidator,
} from '../../views/programming/channel/validators';
import {useChannelSearch} from '../../views/programming/channel/utils';
import {useUserRegions} from 'helpers/useUserRegions';
import {useLanguageList} from 'helpers/useLanguageList';
import {IListPayload} from 'models/generic';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {ChannelFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import {IUserChannelsSearch} from 'models/users';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {getPrefixedUrl} from 'routes';

const channelTitleId = 'channelTitle';

const TABLE_COLUMN_NAME = {
  'No.': 'number',
  'Channel Name': 'name',
  'Active Region': 'activeRegion',
  'Main Categories': 'mainCategories',
  'Legacy Category': 'legacyCategory',
  Published: 'published',
  'Kids Only': 'kidsMode',
  'Office Only': 'plutoOfficeOnly',
  Visibility: 'visibility',
} as const;

const DEFAULT_SEARCH = {
  provider: 'jwplatform',
};

const KIDS_MODE_OPTIONS: ISelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

interface SearchState {
  page: number;
  rowsPerPage: number;
  searchObj: Partial<IChannel>;
  sortCol: keyof typeof TABLE_COLUMN_NAME;
  sortDir: 'asc' | 'dsc';
}

const searchReducer = (prevState: SearchState, data: Partial<SearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

export interface IChannelListProps {
  actionsCol?: boolean;
  addNewChannel?: boolean;
  deviceUpdates?: boolean;
  checkboxCol?: boolean | 'multiple';
  filterByArchived?: boolean;
  showFavoriteSearch?: boolean;
  showFavoriteSearchDrawerOpen?: boolean;
  inModal?: boolean;
  onSelect?: (rows: IChannel[]) => void;
  onRowClick?: (row: IChannel) => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  isSearchExpanded?: boolean;
  searchRegion?: string;
  disableChannels?: IChannel[] | Partial<IChannel>[];
  hideArchivedChannels?: boolean;
}

const orderByLabel = valueToMap => {
  return orderBy(
    valueToMap.map(label => ({label, value: label})),
    ['label'],
  );
};

const ChannelList = React.memo(
  ({
    actionsCol = true,
    addNewChannel = true,
    deviceUpdates = true,
    inModal = false,
    checkboxCol = 'multiple',
    filterByArchived = true,
    showFavoriteSearch = false,
    isSearchExpanded = false,
    showFavoriteSearchDrawerOpen = false,
    searchRegion,
    disableChannels = [],
    hideArchivedChannels = true,
    onSelect,
  }: IChannelListProps) => {
    const history = useHistory();
    const {ableTo, permissions} = useAppPermissions();
    const {activeRegions, isSuccess: isSuccessRegions} = useUserRegions();

    const userActiveRegions = React.useMemo(() => activeRegions.map(ac => ac.code), [activeRegions]);

    const [insertChannel] = useInsertMutation();

    const [searchQuery, searchQueryDispatch] = React.useReducer<React.Reducer<SearchState, Partial<SearchState>>>(
      searchReducer,
      {page: 0, rowsPerPage: 25, searchObj: {}, sortCol: 'No.', sortDir: 'asc'},
    );

    const {page, rowsPerPage, searchObj, sortCol, sortDir} = searchQuery;
    const [userSearch, setUserSearch] = React.useState<IUserChannelsSearch>();
    const [isEmptySearch, setIsEmptySearch] = React.useState(true);
    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [mainCategoriesList, setMainCategoriesList] = React.useState<ISelectOption[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<IChannel[]>([]);
    const [distributionCol, setDistributionCol] = React.useState<string>('include');
    const [selectedDistributionGroup, setSelectedDistributionGroup] = React.useState<string[]>([]);
    const [channelData, setChannelData] = React.useState<IListPayload<IChannel> | undefined>(undefined);
    const [isHideArchivedChannels, setIsHideArchivedChannels] = React.useState<boolean>(hideArchivedChannels);

    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<IChannel>(channelCreateValidator, 'onBlur');

    const {reset: resetDeviceUpdate} = useValidateForm<IChannel>(channelDeviceUpdateValidator, 'onBlur');

    const {
      data: channel,
      error,
      isError,
      isFetching,
    } = useFindChannelQuery(
      {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sort: `${TABLE_COLUMN_NAME[sortCol]}:${sortDir}`,
        ...searchObj,
        activeRegion: searchRegion || searchObj.activeRegion,
        archived: isHideArchivedChannels ? undefined : true,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: Object.keys(searchObj).length === 0,
      },
    );

    const {
      data: mainCategories,
      isError: isMainCategoriesError,
      error: mainCategoriesError,
      isFetching: isMainCategoriesFetching,
    } = useFindMainCategoriesQuery();

    const {
      data: legacyCategories,
      isError: isLegacyCategoriesError,
      error: legacyCategoriesError,
      isFetching: isLegacyCategoriesFetching,
    } = useFindLegacyCategoriesQuery();

    const {data: deviceTypesList, isFetching: isDevicesTypesFetching} = useFindDevicesQuery({
      offset: 0,
      limit: 250,
      sort: 'name:asc',
    });

    const uniqueDeviceTypes = React.useMemo(
      () =>
        orderBy(
          [...new Map(deviceTypesList?.data.map(item => [item['platform'], item])).values()].map(d => ({
            label: `${d.platform}`,
            value: d._id,
          })),
          'label',
        ),
      [deviceTypesList?.data],
    );

    const {languageList, isLanguagesError, languagesError, isLanguagesFetching, searchLanguages} = useLanguageList();

    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: setSearchFields,
      setModel: setSearchModel,
      form: searchForm,
      getValidation: searchGetValidation,
      onBlur: searchOnBlur,
      reset: searchReset,
    } = useValidateForm<IChannel>(channelSearchValidator, 'ask');

    React.useEffect(() => {
      if (disableChannels.length > 0) {
        setSelectedRow([]);
      }
    }, [disableChannels]);

    React.useEffect(() => {
      onSelect?.(selectedRow);
    }, [onSelect, selectedRow]);

    const filteredDeviceIncludedList = React.useMemo(() => {
      const deviceIncludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);
      return orderByLabel(
        deviceIncludedOptionList.flatMap(a => a.label).filter(i => !searchModel.deviceExcluded?.includes(i)),
      );
    }, [deviceTypesList?.data, searchModel.deviceExcluded]);

    const filteredDeviceExcludedList = React.useMemo(() => {
      const deviceExludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);
      return orderByLabel(
        deviceExludedOptionList.flatMap(a => a.label).filter(i => !searchModel.deviceIncluded?.includes(i)),
      );
    }, [deviceTypesList?.data, searchModel.deviceIncluded]);

    const handleSearchSelected = (userSearch: IUserChannelsSearch) => {
      // visually check or uncheck published checkbox
      searchOnChange('published', !!userSearch.model.published);
      setUserSearch(userSearch);
      setSearchModel(userSearch?.model || {});
    };

    React.useEffect(() => {
      if (isError && !isFetching) {
        setChannelData({metadata: {offset: 0, limit: 25, totalCount: 0}, data: []});
      } else {
        setChannelData(channel);
      }
    }, [channel, isError, isFetching]);

    const handleFilteredList = (mainCategoriesArray: IMainCategory[], activeRegion: string) => {
      const list = mainCategoriesArray
        .filter((mc: IMainCategory) => mc.activeRegion === activeRegion)
        .map((mc: IMainCategory) => ({label: mc.name, value: mc.id, order: mc.order}));

      return sortBy(list, 'label');
    };

    React.useEffect(() => {
      const list: ISelectOption[] = handleFilteredList(mainCategories || [], model.activeRegion as string);
      setMainCategoriesList(list);
    }, [model.mainCategories, model.activeRegion, mainCategories]);

    React.useEffect(() => {
      if (isSuccessRegions) {
        setSearchModel({published: true});
        searchQueryDispatch({searchObj: {activeRegions: activeRegions.map(ac => ac.code), published: true}});
      }
    }, [isSuccessRegions, activeRegions, setSearchModel]);

    React.useLayoutEffect(() => {
      if (
        !isMainCategoriesFetching &&
        !isLegacyCategoriesFetching &&
        !isLanguagesFetching &&
        !isDevicesTypesFetching &&
        searchExpanded
      ) {
        setTimeout(() => {
          const channelTitleInput = document.getElementById(channelTitleId);
          channelTitleInput?.focus({
            preventScroll: true,
          });
        });
      }
    }, [
      isDevicesTypesFetching,
      isLanguagesFetching,
      isLegacyCategoriesFetching,
      isMainCategoriesFetching,
      searchExpanded,
    ]);

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let channelId: string | undefined;

      try {
        setIsCreating(true);
        const postModel: Partial<IChannel> = {
          ...model,
          provider: 'jwplatform',
          published: false,
          plutoOfficeOnly: true,
          kidsMode: false,
          directOnly: true,
          isStitched: true,
          number: 0,
          visibility: 'hidden',
          onDemand: false,
          type: 'permanent',
          archived: false,
          tags: [`${model.activeRegion}`],
          description: model.summary,
          autoScheduleDays: 7,
        };

        const newChannel = await insertChannel(trimModel(postModel, 'name')).unwrap();
        channelId = newChannel.id!;

        const toastMsg = navigateTo ? (
          'Channel Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>Channel Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() =>
                history.push(channelRoutes.paths.channelEditDetailsPage.replace(':id', channelId as string))
              }
            >
              View Channel: {postModel.name}
            </Click>
          </Stack>
        );

        Toast.success('Success', toastMsg, 8000);
        setCreateOpen(false);

        searchQueryDispatch({page: 0});
      } catch (e) {
        Toast.error('Error', (e as any).data.message);
      } finally {
        setIsCreating(false);
      }

      return channelId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const channelId = await handleCreate(true);

        if (channelId) {
          history.push(channelRoutes.paths.channelEditDetailsPage.replace(':id', channelId as string));
        }
      } catch (e) {}
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const getChannelEditUrl = (channel: IChannel, pushNoPrefix?: boolean) => {
      let url: string;

      if (ableTo('CHANNEL_CATALOG_VIEW')) {
        url = channelRoutes.paths.channelEditCatalogPage.replace(':id', channel.id);
      } else {
        url = channelRoutes.paths.channelEditDetailsPage.replace(':id', channel.id);
      }

      if (pushNoPrefix) {
        return url;
      }

      return getPrefixedUrl(url);
    };

    const handleEdit = (channel: IChannel) => history.push(getChannelEditUrl(channel, true));

    const [updateChannel] = useUpdateMutation();

    const handleArchive = React.useCallback(
      async (archivedChannel: IChannel) => {
        const archiveRequestData: Partial<IChannel> = {
          archived: true,
        };
        const fields: string[] = ['archived'];

        const {published, id} = archivedChannel;

        if (published) {
          archiveRequestData.published = false;
          fields.push('published');
        }

        try {
          const result: any = await updateChannel({
            id,
            channel: archiveRequestData,
            fields,
          });

          if ('error' in result) Toast.error('error', `${result.error.data.message}.`);
          else Toast.success('Success', `You've archived the channel ${archivedChannel.name}.`);
          return result;
        } catch (e) {
          Toast.error('Error', 'Failed to archive channel. Please try again');
        }
      },
      [updateChannel],
    );

    const handleUnarchive = React.useCallback(
      async (archivedChannel: IChannel) => {
        const archiveRequestData: Partial<IChannel> = {};
        const fields: string[] = [];

        const {archived, id} = archivedChannel;

        if (archived) {
          archiveRequestData.archived = false;
          fields.push('archived');
        }

        try {
          const result: any = await updateChannel({
            id,
            channel: archiveRequestData,
            fields,
          });

          if ('error' in result) Toast.error('error', `${result.error.data.message}.`);
          else Toast.success('Success', `You've unarchived the channel ${archivedChannel.name}.`);
          return result;
        } catch (e) {
          Toast.error('Error', 'Failed to unarchive channel. Please try again');
        }
      },
      [updateChannel],
    );
    /**
     * custom response message for bulk update devices operation.
     **/
    const customResultMessage = React.useCallback(() => {
      const selectedDevicesCount = (model.platform as string[]).length!;
      const firstThreeDeviceNames = model.platform?.slice(0, 3);
      const firstThreeChannelNames = selectedRow.slice(0, 3).map(n => n.name);

      const bulkUpdatedToastMsg = {
        channelName:
          selectedRow.length > 3
            ? `${firstThreeChannelNames} and ${selectedRow.length - 3} other channels`
            : `${firstThreeChannelNames} channel`,
        platformName:
          selectedDevicesCount > 3
            ? `${firstThreeDeviceNames} and ${selectedDevicesCount - 3} other devices`
            : `${firstThreeDeviceNames} device`,
      };

      if (distributionCol === 'exclude') {
        return `You've excluded ${bulkUpdatedToastMsg.platformName} from ${bulkUpdatedToastMsg.channelName}.`;
      }

      if (distributionCol === 'remove') {
        return `You've removed ${bulkUpdatedToastMsg.platformName} from ${bulkUpdatedToastMsg.channelName}.`;
      }

      return `You've included ${bulkUpdatedToastMsg.platformName} to ${bulkUpdatedToastMsg.channelName}.`;
    }, [model.platform, selectedRow, distributionCol]);

    const [updateChannelDeviceTypesBulk] = useBulkUpdateMutation();

    const updateDevicesInBulk = React.useCallback(async () => {
      try {
        const bulkUpdatedChannels = await updateChannelDeviceTypesBulk({
          devices: selectedDistributionGroup,
          includeGroup: distributionCol === 'include' ? selectedRow.map(v => v.id) : [],
          excludeGroup: distributionCol === 'exclude' ? selectedRow.map(v => v.id) : [],
          removeGroup: distributionCol === 'remove' ? selectedRow.map(v => v.id) : [],
        });

        setDeviceUpdateOpen(false);
        setSelectedRow([]);
        setSelectedDistributionGroup([]);

        if ('error' in bulkUpdatedChannels) {
          if ((bulkUpdatedChannels.error as any)?.data.error) {
            Toast.error('error', `${(bulkUpdatedChannels.error as any)?.data.error}.`);
          } else {
            Toast.error('error', `${bulkUpdatedChannels.error}.`);
          }
        } else Toast.success('Success', customResultMessage());
        return bulkUpdatedChannels;
      } catch (e) {
        Toast.error('Error', 'Failed to perform device updates. Please try again');
      }
    }, [updateChannelDeviceTypesBulk, selectedDistributionGroup, distributionCol, selectedRow, customResultMessage]);

    const [createOpen, setCreateOpen] = React.useState(false);
    const openCreate = () => {
      resetCreate();
      setCreateOpen(true);
    };

    const [deviceUpdateOpen, setDeviceUpdateOpen] = React.useState(false);
    const openDeviceUpdate = () => {
      resetDeviceUpdate();
      setDeviceUpdateOpen(true);
    };

    const closeDeviceUpdate = React.useCallback(() => {
      resetDeviceUpdate();
      setSelectedDistributionGroup([]);
      resetCreate();
      setDeviceUpdateOpen(false);
    }, [resetDeviceUpdate, resetCreate]);

    const {clearSearch} = useChannelSearch();

    const initialSearch = () => {
      setSearchModel(DEFAULT_SEARCH);
      setIsEmptySearch(true);
      setUserSearch(undefined);
      clearSearch(true);
    };

    const changeSort = (sortDir, colName) => {
      searchQueryDispatch({
        sortDir: sortDir !== 'asc' ? 'dsc' : 'asc',
        sortCol: TABLE_COLUMN_NAME[colName] ? colName : 'No.',
        page: 0,
      });
    };

    const [isCreating, setIsCreating] = React.useState(false);

    const handleSearchClear = React.useCallback(() => {
      searchReset();
      setUserSearch(undefined);
      setIsHideArchivedChannels(true);

      setTimeout(() =>
        searchQueryDispatch({
          searchObj: {
            activeRegions: userActiveRegions,
          },
          page: 0,
        }),
      );
    }, [searchReset, userActiveRegions]);

    const handleSelect = (rows: IChannel[]) => {
      if (rows.length === 0) {
        setSelectedRow(differenceBy(selectedRow, channelData?.data as IChannel[], 'id'));
      }

      if (rows.length === channelData?.data.length) {
        const allSelected = uniqBy(selectedRow.concat(rows), 'id');
        const enabledSelection = differenceBy(allSelected, disableChannels, 'id');
        setSelectedRow(enabledSelection);
      }
    };

    const handleSelectLog = (row, selected) => {
      setSelectedRow(selected ? curr => uniqBy(curr.concat(row), 'id') : curr => curr.filter(e => e.id !== row.id));
    };

    const handleSearch = React.useCallback(async () => {
      const searchFields = await searchGetValidation();

      const searchActiveRegions =
        (searchModel.activeRegions || []).length === 0 ? userActiveRegions : searchModel.activeRegions;
      setIsHideArchivedChannels(!searchModel.archived);

      searchQueryDispatch({
        searchObj: {
          ...searchFields.model,
          activeRegions: searchActiveRegions,
        },
        page: 0,
      });
    }, [searchGetValidation, userActiveRegions, searchModel.activeRegions, searchModel.archived]);

    React.useEffect(() => {
      const searchObjWithUserActiveRegion = {
        ...userSearch?.model,
        activeRegions: (searchModel.activeRegions || []).length === 0 ? userActiveRegions : searchModel.activeRegions,
      };
      searchQueryDispatch({
        searchObj: searchObjWithUserActiveRegion,
        sortCol: (userSearch?.sortCol || 'No.') as keyof typeof TABLE_COLUMN_NAME,
        sortDir: userSearch?.sortDir || 'asc',
        page: 0,
      });
      // avoid adding userActiveRegions in dependency hook not to trigger api multiple time.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearch]);

    if (isError && (error as FetchBaseQueryError).status != 404) {
      return <CrudError error='Error loading page data' />;
    }

    if (isMainCategoriesError || isLegacyCategoriesError || isLanguagesError) {
      const err = mainCategoriesError || legacyCategoriesError || languagesError;
      return <CrudError error={err} />;
    }

    if (isMainCategoriesFetching || isLegacyCategoriesFetching || isLanguagesFetching || isDevicesTypesFetching) {
      return (
        <Box fullHeight={true}>
          <Spinner center={true} minHeight='9.375rem' size='xlarge' />
        </Box>
      );
    }

    return (
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box
              background='pewter'
              paddingY={inModal ? 'none' : 'medium'}
              paddingRight='medium'
              paddingLeft={inModal ? 'none' : 'medium'}
              fullHeight={true}
            >
              <Cover scrolling={true} gutter='medium'>
                <Template label='header'>
                  <Stack space='medium'>
                    <Cluster align='center' justify='space-between'>
                      <Icon icon='tune' space='small' size='large' iconAlign='center'>
                        <Heading level='h4'>Search Filters</Heading>
                      </Icon>
                      <Icon icon='collapseleft' size='large' onClick={() => setSearchExpanded(!searchExpanded)} />
                    </Cluster>
                    {showFavoriteSearch && (
                      <ChannelFavoriteSearch
                        searchModel={{
                          name: '',
                          model: searchModel,
                          sortCol: sortCol,
                          sortDir: sortDir,
                        }}
                        getValidation={searchGetValidation}
                        searchSelected={userSearch}
                        onSearchSelected={handleSearchSelected}
                        onClearSelection={handleSearchClear}
                        isFavoriteSearchDrawerOpen={showFavoriteSearchDrawerOpen}
                      />
                    )}
                    {/* <div>Favorite searchs</div> */}
                    <Divider color='graphite' />
                  </Stack>
                </Template>
                <Template label='cover'>
                  {searchExpanded && (
                    <form
                      onSubmit={ev => {
                        ev.preventDefault();
                        handleSearch();
                      }}
                    >
                      <Stack space='small'>
                        {/* Using this to allow pressing enter to submit form */}
                        <input type='submit' style={{display: 'none'}} />
                        <Stack space='xxlarge'>
                          <Stack space='small'>
                            <Label>Status</Label>
                            <Stack space='small'>
                              <Checkbox
                                label='Published'
                                value={searchModel.published}
                                onChange={val => {
                                  if (val) {
                                    // https://plutotv.atlassian.net/browse/CMS-3381
                                    // if published, archived status does not matter
                                    setSearchFields({archived: undefined});
                                  }
                                  setSearchFields({published: val});
                                }}
                              />
                              <Checkbox
                                label='Unpublished'
                                value={searchModel.unpublished}
                                onChange={val => {
                                  if (val) {
                                    // https://plutotv.atlassian.net/browse/CMS-3381
                                    // if unpublished, archived status does not matter
                                    setSearchFields({archived: undefined});
                                  }
                                  setSearchFields({unpublished: val});
                                }}
                              />
                              {filterByArchived && (
                                <Checkbox
                                  label='Archived'
                                  onChange={val => {
                                    /**
                                     * https://plutotv.atlassian.net/browse/CMS-3381
                                     * Archived checked must uncheck Published and Unpublished checkboxes
                                     */

                                    if (searchModel.published || searchModel.unpublished) {
                                      setSearchFields({published: !val, unpublished: !val});
                                    }
                                    setSearchFields({archived: val === true ? val : undefined});
                                  }}
                                  value={searchModel.archived}
                                />
                              )}
                            </Stack>
                          </Stack>
                          <FormItem
                            {...searchForm.name}
                            onBlur={() => {
                              searchOnBlur('name');
                            }}
                          >
                            <TextInput
                              id={channelTitleId}
                              clearable={true}
                              placeholder='Title'
                              value={searchModel.name}
                              onChange={val => {
                                searchOnChange('name', val);
                              }}
                            />
                          </FormItem>
                        </Stack>
                        <FormItem {...searchForm.number} onBlur={() => searchOnBlur('number')}>
                          <TextInput
                            placeholder='Number'
                            clearable={true}
                            value={searchModel.number}
                            type='number'
                            onChange={val => searchOnChange('number', val)}
                          />
                        </FormItem>
                        <FormItem
                          {...searchForm.activeRegions}
                          label='Active Region'
                          state={searchRegion ? 'disabled' : ''}
                        >
                          <Select
                            clearable={true}
                            multiselect={true}
                            placeholder='Select Active Region'
                            id='activeRegions'
                            value={
                              searchRegion
                                ? [{label: searchRegion, value: searchRegion}]
                                : searchModel.activeRegions?.map(v => ({label: v, value: v})) || ([] as ISelectOption[])
                            }
                            options={activeRegions.map(ar => ({
                              label: `${ar.name} (${ar.code})`,
                              value: ar.code,
                            }))}
                            onChange={value => {
                              setSearchFields({
                                activeRegions: value ? (value as ISelectOption[]).map(v => v.value) : [],
                              });
                            }}
                            predicate='value'
                          />
                        </FormItem>
                        <FormItem {...searchForm.kidsMode} label='Kids Mode'>
                          <Select
                            id='kidsMode'
                            clearable={true}
                            predicate='value'
                            placeholder='Kids mode'
                            options={KIDS_MODE_OPTIONS}
                            value={{label: '', value: searchModel.kidsMode}}
                            onChange={value => {
                              setSearchFields({kidsMode: value?.value});
                            }}
                          />
                        </FormItem>
                        <FormItem {...searchForm.deviceIncluded}>
                          <Select
                            id='deviceIncluded'
                            clearable={true}
                            placeholder='Select Devices'
                            multiselect={true}
                            value={searchModel.deviceIncluded?.map(p => ({label: p, value: p}))}
                            options={filteredDeviceIncludedList}
                            onChange={value => {
                              setSearchFields({
                                deviceIncluded: ((value as ISelectOption[]) || []).map(v => v.value),
                              });
                            }}
                            predicate='value'
                            searchable={true}
                            onSearch={term =>
                              orderBy(
                                (filteredDeviceIncludedList || [])
                                  .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                  .map(p => ({label: p.label, value: p.value}), 'label'),
                              ) || ([] as ISelectOption[])
                            }
                          />
                        </FormItem>
                        <FormItem {...searchForm.deviceExcluded}>
                          <Select
                            id='deviceExcluded'
                            clearable={true}
                            placeholder='Select Devices'
                            multiselect={true}
                            value={searchModel.deviceExcluded?.map(p => ({label: p, value: p}))}
                            options={filteredDeviceExcludedList}
                            onChange={value => {
                              setSearchFields({
                                deviceExcluded: ((value as ISelectOption[]) || []).map(v => v.value),
                              });
                            }}
                            predicate='value'
                            searchable={true}
                            onSearch={term =>
                              orderBy(
                                (filteredDeviceExcludedList || [])
                                  .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                  .map(p => ({label: p.label, value: p.value}), 'label'),
                              ) || ([] as ISelectOption[])
                            }
                          />
                        </FormItem>
                        <FormItem
                          {...searchForm.samsungServiceId}
                          onBlur={() => {
                            searchOnBlur('samsungServiceId');
                          }}
                          label='Samsung Service ID'
                        >
                          <TextInput
                            clearable={true}
                            placeholder='ID'
                            value={searchModel.samsungServiceId}
                            onChange={val => {
                              searchOnChange('samsungServiceId', val);
                            }}
                          />
                        </FormItem>
                      </Stack>
                    </form>
                  )}
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='small'>
                      <Button ghost={true} state={isFetching ? 'disabled' : ''} onClick={handleSearchClear}>
                        Clear
                      </Button>
                      <Button
                        id='searchButton'
                        type='primary'
                        state={isFetching ? 'thinking' : ''}
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </Cluster>
                  </Cluster>
                </Template>
              </Cover>
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Cluster align='end' space='small'>
                <Heading level='h1'>Channels</Heading>
                <Cluster space='xxsmall' align='center'>
                  <Icon
                    icon='tune'
                    space='xxxsmall'
                    verticalAlign='bottom'
                    lineHeight='0px'
                    onClick={() => setSearchExpanded(!searchExpanded)}
                  >
                    {isError ? '0' : withThousandsSeparator(channelData?.metadata.totalCount || 0)} Items
                  </Icon>
                  {!isEmptySearch && !isFetching && (
                    <Icon
                      icon='cancel'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={initialSearch}
                    >
                      Clear Filter
                    </Icon>
                  )}
                </Cluster>
              </Cluster>
              <Cluster space='small' align='center'>
                {deviceUpdates && (
                  <>
                    {/* This button has a state of disabled until at least one checkbox on the table is checked */}
                    <Button
                      permission={permissions.CHANNEL_EDIT}
                      onClick={() => openDeviceUpdate()}
                      state={selectedRow.length > 0 ? '' : 'disabled'}
                    >
                      Device Updates
                    </Button>
                    <Drawer width='24rem' isOpen={deviceUpdateOpen} onClose={closeDeviceUpdate}>
                      <Box
                        paddingY={inModal ? 'none' : 'medium'}
                        paddingRight='medium'
                        paddingLeft={inModal ? 'none' : 'medium'}
                      >
                        <Stack space='medium'>
                          <Heading level='h4'>Device Updates</Heading>
                          <Divider color='graphite' />
                          <Stack space='xsmall'>
                            <Stack>
                              <FormItem child='RadioGroup' label='Update Type'>
                                <RadioGroup
                                  layout='horizontal'
                                  value={{value: distributionCol, label: 'Include'}}
                                  options={[
                                    {label: 'Include', value: 'include'},
                                    {label: 'Exclude', value: 'exclude'},
                                    {label: 'Remove', value: 'remove'},
                                  ]}
                                  onChange={value => setDistributionCol(value.value)}
                                />
                              </FormItem>
                              <FormItem label='Select Devices'>
                                <Select
                                  id='distribution'
                                  visualStyle='light'
                                  multiselect={true}
                                  clearable={true}
                                  value={selectedDistributionGroup.map(v => ({value: v, label: v}))}
                                  options={uniqueDeviceTypes}
                                  onChange={value => {
                                    setSelectedDistributionGroup(((value as ISelectOption[]) || []).map(v => v.value));
                                    setFields({
                                      distribution: {
                                        [distributionCol]: ((value as ISelectOption[]) || []).map(v => v.value),
                                      },
                                      platform: (value || []).map(v => v.label),
                                    });
                                  }}
                                  predicate='value'
                                  searchable={true}
                                  onSearch={term =>
                                    orderBy(
                                      (uniqueDeviceTypes || [])
                                        .filter(d => d.label.toLowerCase().startsWith(term.toLowerCase()))
                                        .map(d => ({label: d.label, value: d.value}), 'label'),
                                    ) || ([] as ISelectOption[])
                                  }
                                />
                              </FormItem>
                            </Stack>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Cluster space='small'>
                                <Button onClick={closeDeviceUpdate}>Cancel</Button>
                                <Button
                                  type='primary'
                                  state={selectedDistributionGroup.length > 0 ? '' : 'disabled'}
                                  onClick={() => updateDevicesInBulk()}
                                >
                                  Save Updates
                                </Button>
                              </Cluster>
                            </Cluster>
                          </Stack>
                        </Stack>
                      </Box>
                    </Drawer>
                  </>
                )}
                {addNewChannel ? (
                  <>
                    <Button type='primary' onClick={() => openCreate()} permission={permissions.CHANNEL_CREATE}>
                      + New Channel
                    </Button>
                    <Dialog isOpen={createOpen} onClose={handleCancel} width='42.8125rem' height='39.5rem'>
                      <Template label='header'>
                        <Heading level='h2'>Create Channel</Heading>
                      </Template>
                      <Template label='body'>
                        <Stack space='small'>
                          <FormItem {...form.name} onBlur={() => onBlur('name')}>
                            <TextInput
                              onChange={value => {
                                onChange('name', value);
                              }}
                              id='title'
                            />
                          </FormItem>
                          <Grid gap='small' columnGap='xlarge'>
                            <FormItem {...form?.activeRegion} onBlur={() => onBlur('activeRegion')}>
                              <Select
                                onChange={value => {
                                  const activeRegion = value.value;
                                  setFields({
                                    activeRegion,
                                  });
                                }}
                                value={{label: model.activeRegion || '', value: model.activeRegion}}
                                id='activeRegtion'
                                predicate='value'
                                appendToBody={true}
                                options={activeRegions.map(ar => ({
                                  label: `${ar.name} (${ar.code})`,
                                  value: ar.code.toLowerCase(),
                                }))}
                              />
                            </FormItem>
                          </Grid>
                          <FormItem {...form?.categories} onBlur={() => onBlur('categories')}>
                            <Select
                              searchPlaceholder='Search for main category'
                              appendToBody={true}
                              onChange={value =>
                                setFields({
                                  categories: (value || []).map(v => {
                                    return {
                                      catId: v.value,
                                      order: v.order,
                                    };
                                  }),
                                })
                              }
                              value={model.categories?.map(d => ({
                                label: d.name || '',
                                value: d.catId,
                                order: d.order,
                              }))}
                              id='categories'
                              multiselect={true}
                              clearable={true}
                              addAll={true}
                              predicate='value'
                              searchable={true}
                              onSearch={val =>
                                orderBy(
                                  (mainCategoriesList || [])
                                    .filter(mc => mc.label.toLowerCase().startsWith(val.toLowerCase()))
                                    .map(mc => ({label: mc.label, value: mc.value, order: mc.order}), 'label'),
                                ) || []
                              }
                              options={mainCategoriesList}
                            />
                          </FormItem>
                          <Grid gap='small' columnGap='xlarge'>
                            <FormItem {...form?.category} onBlur={() => onBlur('category')}>
                              <Select
                                onChange={value => setFields({category: value.label})}
                                value={{label: model.category || ''}}
                                id='category'
                                appendToBody={true}
                                searchable={true}
                                searchPlaceholder='Search for legacy category'
                                onSearch={val =>
                                  orderBy(
                                    (legacyCategories || [])
                                      .filter(lc => lc.name.toLowerCase().startsWith(val.toLowerCase()))
                                      .map(lc => ({label: lc.name})),
                                    'label',
                                  ) || []
                                }
                                options={orderBy(
                                  (legacyCategories || []).map(lc => ({label: lc.name})),
                                  'label',
                                )}
                              />
                            </FormItem>
                            <FormItem {...form?.metadataLanguage} onBlur={() => onBlur('metadataLanguage')}>
                              <Select
                                onChange={value => setFields({metadataLanguage: value.value})}
                                value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                                id='metadataLanguage'
                                appendToBody={true}
                                predicate={d => d.value?.toLowerCase() || ''}
                                searchable={true}
                                searchPlaceholder='Search for language'
                                onSearch={searchLanguages}
                                options={languageList}
                              />
                            </FormItem>
                          </Grid>
                          <FormItem {...form.summary} onBlur={() => onBlur('summary')}>
                            <Textarea
                              value={model.summary}
                              onChange={v => {
                                onChange('summary', v);
                              }}
                              id='summary'
                              minHeight='6.25rem'
                            />
                          </FormItem>
                        </Stack>
                      </Template>
                      <Template label='footer'>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button ghost={true} onClick={handleCancel} id='cancelButton'>
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={() => handleCreate()}
                              id='createButton'
                            >
                              Create
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={handleCreateAndEdit}
                              id='createEditButton'
                            >
                              Create and Edit
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Template>
                    </Dialog>
                  </>
                ) : (
                  <Box height='1.7rem'></Box>
                )}
              </Cluster>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='2px'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : (channel?.data.length as number) > 0 ? 'xsmall' : 'medium'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <Table<IChannel>
                id='channelsTable'
                loading={isFetching || !channelData}
                fixedHeader={true}
                predicate='id'
                wrapContent={true}
                sortDir={sortDir}
                sortCol={sortCol}
                onSort={colName => changeSort(sortDir === 'asc' ? 'dsc' : 'asc', colName)}
                selectable={checkboxCol as 'multiple'}
                rowDisabled={row => disableChannels.some(channel => channel.id === row.id)}
                selected={selectedRow as any}
                onSelect={handleSelect}
                selectLog={handleSelectLog}
                cols={[
                  {
                    label: 'No.',
                    field: 'number',
                    sortable: true,
                    colMinWidth: '5rem',
                  },
                  {
                    label: 'Channel Name',
                    sortable: true,
                    colMinWidth: '22.125rem',
                    transform: row => (
                      <TdLink row={row} title={row.name} url={getChannelEditUrl(row)} onClick={handleEdit} />
                    ),
                  },
                  {
                    label: 'Active Region',
                    transform: row => row?.activeRegion?.toUpperCase(),
                    sortable: true,
                    colMinWidth: '9.6875rem',
                  },
                  {
                    label: 'Main Categories',
                    transform: row => row.categories.map(c => c.name!).join(' + '),
                    sortable: true,
                    colMinWidth: '13rem',
                  },
                  {
                    label: 'Legacy Category',
                    field: 'category',
                    sortable: true,
                    colMinWidth: '13rem',
                  },
                  {
                    label: 'Published',
                    sortable: true,
                    colMinWidth: '9.5rem',
                    transform: row => (
                      <Status
                        label={row.published ? 'Published' : 'Unpublished'}
                        state={row.published ? 'success' : 'neutral'}
                      />
                    ),
                  },
                  {
                    label: 'Kids Only',
                    sortable: true,
                    colMinWidth: '8.875rem',
                    transform: row => (
                      <Status label={row.kidsMode ? 'Yes' : 'No'} state={row.kidsMode ? 'success' : 'neutral'} />
                    ),
                  },
                  {
                    label: 'Office Only',
                    sortable: true,
                    colMinWidth: '8.875rem',
                    transform: row => (
                      <Status
                        label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                        state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                      />
                    ),
                  },
                  {
                    label: 'Visibility',
                    sortable: true,
                    colMinWidth: '6.875rem',
                    transform: row => `${row.visibility.charAt(0).toUpperCase() + row.visibility.slice(1)}`,
                  },
                  ...(ableTo('CHANNEL_EDIT') && actionsCol
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: row => (
                            <TableActions
                              altTitle={!row.archived ? 'channel' : undefined}
                              row={row}
                              icons={ableTo('CHANNEL_EDIT') ? ['edit'] : []}
                              archiveOption={!row.archived && ableTo('CHANNEL_EDIT')}
                              unarchiveOption={!row.published && row.archived && ableTo('CHANNEL_EDIT')}
                              onClick={(row, icon) => {
                                switch (icon) {
                                  case 'edit':
                                    handleEdit(row);
                                    break;
                                  case 'archive':
                                    handleArchive(row);
                                    break;
                                  case 'unarchive':
                                    handleUnarchive(row);
                                    break;
                                  default:
                                }
                              }}
                            />
                          ),
                        } as ITableCol<IChannel>,
                      ]
                    : []),
                ]}
                rowStatus={row => (disableChannels.some(channel => channel.id === row.id) ? ('highlight' as any) : '')}
                rows={channelData?.data || []}
              >
                <Template label='loading'>
                  <Cluster space='small' align='center'>
                    <Spinner />
                    <Paragraph>Loading Channel List</Paragraph>
                  </Cluster>
                </Template>
                <Template label='empty'>
                  <Notification type='warning'>There are no channels currently available.</Notification>
                </Template>
              </Table>
            </Box>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {(channelData?.data.length as number) > 0 && (
                <Pagination
                  perPage={rowsPerPage as 25 | 50 | 75 | 100}
                  currentPage={page}
                  total={channelData?.metadata.totalCount || 0}
                  onPageChange={page =>
                    searchQueryDispatch({
                      page,
                    })
                  }
                  onPerPageChange={perPage => {
                    searchQueryDispatch({
                      rowsPerPage: perPage,
                      page: 0,
                    });
                  }}
                />
              )}
            </Cluster>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

ChannelList.displayName = 'ChannelList';
export default ChannelList;
