import * as React from 'react';
import {cloneDeep, omit, orderBy, some} from 'lodash-es';
import {useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query/react';

import {
  Button,
  Box,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Cluster,
  Copy,
  FormItem,
  Grid,
  Icon,
  ITableCol,
  Popover,
  Select,
  Spinner,
  Stack,
  Table,
  Template,
  Textarea,
  TextInput,
  TIcons,
  Help,
  Toast,
} from '@pluto-tv/assemble';

import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';

import {ISeason} from 'models/series';
import {useUserModelRatings} from 'helpers/useUserRatings';

import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindRatingDescriptorsQuery} from 'features/ratingDescriptors/ratingDescriptorsApi';
import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useFindQuery as useFindEpisodesQuery} from 'features/episodes/episodesApi';

import {INestedSeriesProps} from '../nestedPropsInterface';
import SeasonForm from '../../components/season-form';
import {useSeriesPermissions} from '../../permissions/useSeriesPermissions';

interface ISeasonsPopover {
  add?: boolean;
  [key: number]: boolean;
}

export default ({model, setFields, onBlur, onChange, form, pristineModel}: INestedSeriesProps): JSX.Element => {
  const {id}: {id: string} = useParams();
  const {CAN_EDIT, editPermission} = useSeriesPermissions(model);
  const {
    contentRatings,
    isFetching: isFetchingContentRatings,
    isError: isErrorContentRatings,
  } = useUserModelRatings(model);

  const [seasonPopoverOpen, setSeasonPopoverOpen] = React.useState<ISeasonsPopover>({});

  const {
    data: episodes,
    isError: isErrorEpisodes,
    isLoading: isFetchingEpisodes,
  } = useFindEpisodesQuery(
    !pristineModel.activeRegion
      ? skipToken
      : {seriesID: id, limit: 1000, activeRegions: [pristineModel.activeRegion.toUpperCase()]},
    {
      refetchOnMountOrArgChange: false,
    },
  );

  const {data: genres, isFetching: isFetchingGenres, isError: isErrorGenres} = useFindGenresQuery();
  const {data: categories, isFetching: isFetchingCategories, isError: isErrorCategories} = useFindCategoriesQuery();
  const {data: languages, isFetching: isFetchingLanguages, isError: isErrorLanguages} = useFindLanguagesQuery();
  const {
    data: ratingDescriptors,
    isFetching: isFetchingRatingDescriptors,
    isError: isErrorRatingDescriptors,
  } = useFindRatingDescriptorsQuery();

  const seasonData: ISeason[] = React.useMemo(
    () =>
      (model?.seasons || [])
        .map(season => ({
          ...season,
          episodes: (episodes?.data || []).filter(ep => +ep.season === +season.number).length,
        }))
        .sort((a, b) => +a.number - +b.number),
    [episodes, model],
  );

  const handleSeasonClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      if (model.seasons && model.seasons.length <= 1) {
        Toast.error('This action cannot be completed. Must be at least 1 Season');
        return;
      }

      const cloned = cloneDeep(model.seasons);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        seasons: cloned,
      });
    } else if (icon === 'edit') {
      setSeasonPopoverOpen({[index]: true});
    }
  };

  const handleSeasonsUpdate = (season: ISeason, index = -1) => {
    const cloned = cloneDeep(model.seasons || []);

    if (index >= 0) {
      cloned.splice(index, 1, season);
    } else {
      if (some(cloned, s => s.number === season.number)) {
        Toast.error('Season number already exists. Please try a different one');
        return;
      }
      cloned.push(season);
    }

    setFields({
      seasons: cloned.map(s => omit(s, 'episodes')),
    });

    setSeasonPopoverOpen({});
  };

  if (
    isErrorGenres ||
    isErrorRatingDescriptors ||
    isErrorCategories ||
    isErrorLanguages ||
    isErrorContentRatings ||
    isErrorEpisodes
  ) {
    return <CrudError error='Error loading page data' />;
  }

  if (
    isFetchingGenres ||
    isFetchingRatingDescriptors ||
    isFetchingCategories ||
    isFetchingLanguages ||
    isFetchingContentRatings ||
    isFetchingEpisodes
  ) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  if (!model) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <form id='seriesDetailsForm'>
            <Stack space='medium'>
              <FormItem {...form.name} onBlur={() => onBlur('name')} permission={editPermission}>
                <TextInput
                  onChange={value => onChange('name', value)}
                  value={model.name}
                  id='name'
                  placeholder='Name'
                />
              </FormItem>
              <FormItem {...form.displayName} onBlur={() => onBlur('displayName')} permission={editPermission}>
                <TextInput
                  onChange={value => onChange('displayName', value)}
                  value={model.displayName}
                  id='displayName'
                  placeholder='Display name'
                />
              </FormItem>
              <FormItem {...form.summary} onBlur={() => onBlur('summary')} permission={editPermission}>
                <Textarea
                  onChange={value => onChange('summary', value)}
                  value={model.summary}
                  id='summary'
                  placeholder='Short description'
                  minHeight='6.25rem'
                />
              </FormItem>
              <FormItem {...form.description} onBlur={() => onBlur('description')} permission={editPermission}>
                <Textarea
                  onChange={value => onChange('description', value)}
                  value={model.description}
                  id='description'
                  minHeight='6.25rem'
                  placeholder='Long description'
                />
              </FormItem>
              <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
                <FormItem {...form.rating} permission={editPermission}>
                  <Select
                    placeholder='Please select rating'
                    onChange={value => setFields({rating: value.label})}
                    value={{label: model.rating || ''}}
                    id='rating'
                    options={contentRatings}
                  />
                </FormItem>
                <FormItem {...form.ratingDescriptors} permission={editPermission}>
                  <Select
                    placeholder='Please select rating descriptors'
                    clearable
                    multiselect
                    onChange={value => setFields({ratingDescriptors: value?.map(v => v.value)})}
                    value={model.ratingDescriptors?.map(d => ({
                      label: ratingDescriptors?.find(rd => rd.slug == d)?.displayName || '',
                      value: d,
                    }))}
                    id='ratingDescriptors'
                    predicate='value'
                    options={(ratingDescriptors || [])
                      .filter(rd => rd.region.toLowerCase() === model.activeRegion)
                      .map(rd => ({
                        label: `${rd.displayName} ${rd.label ? `(${rd.label})` : ''}`,
                        value: rd.slug,
                      }))}
                  />
                </FormItem>
                <FormItem {...form.genre} permission={editPermission}>
                  <Select
                    placeholder='Please select genre'
                    onChange={value => setFields({genre: value.label, subGenre: undefined})}
                    value={{label: model.genre || ''}}
                    id='genre'
                    options={orderBy(
                      (genres || []).map(g => ({label: g.genre})),
                      'label',
                    )}
                    searchable={true}
                    searchPlaceholder='Search for genre'
                    onSearch={val =>
                      orderBy(
                        (genres || [])
                          .filter(g => g.genre.toLowerCase().indexOf(val.toLowerCase()) > -1)
                          .map(g => ({label: g.genre}), 'label'),
                      ) || []
                    }
                  />
                </FormItem>
                <FormItem {...form.subGenre} permission={editPermission}>
                  <Select
                    onChange={value => setFields({subGenre: value.label})}
                    placeholder='Please select sub-genre'
                    value={{label: model.subGenre || ''}}
                    id='subGenre'
                    options={orderBy(
                      ((model.genre && genres?.find(g => g.genre === model.genre)?.subGenres) || []).map(sg => ({
                        label: sg,
                      })),
                      'label',
                    )}
                    searchable={true}
                    searchPlaceholder='Search for sub-genre'
                    onSearch={val =>
                      orderBy(
                        ((model.genre && genres?.find(g => g.genre === model.genre)?.subGenres) || [])
                          .filter(sg => sg.toLowerCase().indexOf(val.toLowerCase()) > -1)
                          .map(sg => ({label: sg}), 'label'),
                      ) || []
                    }
                  />
                </FormItem>
                <FormItem {...form.category} permission={editPermission}>
                  <Select
                    placeholder='Please select category'
                    onChange={value => setFields({category: value?.label, subCategory: undefined})}
                    value={{label: model.category || ''}}
                    id='category'
                    options={orderBy(
                      (categories || []).map(category => ({label: category.category})),
                      'label',
                    )}
                    searchable={true}
                    searchPlaceholder='Search for category'
                    onSearch={val =>
                      orderBy(
                        (categories || [])
                          .filter(item => item.category.toLowerCase().indexOf(val.toLowerCase()) > -1)
                          .map(item => ({label: item.category}), 'label'),
                      ) || []
                    }
                  />
                </FormItem>
                <FormItem {...form.subCategory} permission={editPermission}>
                  <Select
                    onChange={value => setFields({subCategory: value.label})}
                    value={{label: model.subCategory || ''}}
                    placeholder='Please select sub-category'
                    id='subCategory'
                    options={orderBy(
                      (
                        (model.category && categories?.find(g => g.category === model.category)?.subCategories) ||
                        []
                      ).map(sc => ({label: sc})),
                      'label',
                    )}
                    searchable={true}
                    searchPlaceholder='Search for sub-category'
                    onSearch={val =>
                      orderBy(
                        ((model.category && categories?.find(g => g.category === model.category)?.subCategories) || [])
                          .filter(item => item.toLowerCase().indexOf(val.toLowerCase()) > -1)
                          .map(item => ({label: item}), 'label'),
                      ) || []
                    }
                  />
                </FormItem>
              </Grid>
              <FormItem {...form.metadataLanguage} permission={editPermission}>
                <Select
                  onChange={value => setFields({metadataLanguage: value.value})}
                  value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                  id='metadataLanguage'
                  predicate='value'
                  placeholder='Please select metadata language'
                  searchable={true}
                  searchPlaceholder='Search for language'
                  onSearch={val =>
                    orderBy(
                      (languages || [])
                        .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                        .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                    ) || []
                  }
                  options={orderBy(
                    (languages || []).map(language => ({label: language.name, value: language.iso639_1})),
                    'label',
                  )}
                />
              </FormItem>
            </Stack>
          </form>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Series Information'>
          <Stack space='xxlarge'>
            <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
              <FormItem label='Created At' child='Copy'>
                <Copy
                  id='createdAt'
                  text={new Date(model.createdAt!).toLocaleString()}
                  toCopy={new Date(model.createdAt!).toLocaleString()}
                />
              </FormItem>
              <FormItem label='Updated At' child='Copy'>
                <Copy
                  id='updatedAt'
                  text={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                  toCopy={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                />
              </FormItem>
              <FormItem label='UUID' child='Copy'>
                <Copy id='contentUUID' text={model.contentUUID || 'N/A'} toCopy={model.contentUUID || 'N/A'} />
              </FormItem>
              <FormItem label='Series ID' child='Copy'>
                <Copy text={model.id} toCopy={model.id} id='seriesId' />
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
        <ContentBox title='Seasons'>
          <Stack space='small'>
            <Table
              maxHeight='16.25rem'
              flushTop={true}
              id='episodeSeason'
              fixedHeader={true}
              cols={[
                {
                  label: 'Season',
                  field: 'number',
                },
                {
                  label: 'Episodes',
                  field: 'episodes',
                },
                {
                  label: 'Synopsis',
                  field: 'synopsis',
                  colMaxWidth: '31.25rem',
                },
                ...(CAN_EDIT
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: (row, _col, index) => (
                          <Cluster>
                            <TableActions
                              row={row}
                              icons={[]}
                              deleteOption={row?.episodes ? false : true}
                              displayField='name'
                              altTitle='season'
                              onClick={(row, icon) => handleSeasonClick(icon, index)}
                            >
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={seasonPopoverOpen[index]}
                                onClickOutside={() => setSeasonPopoverOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon space='small' icon='edit' onClick={() => handleSeasonClick('edit', index)} />
                                </Template>
                                <Template label='popover'>
                                  <SeasonForm
                                    onCancel={() => setSeasonPopoverOpen({})}
                                    visible={seasonPopoverOpen[index]}
                                    value={row}
                                    onSave={val => handleSeasonsUpdate(val, index)}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          </Cluster>
                        ),
                      } as ITableCol<ISeason>,
                    ]
                  : []),
              ]}
              rows={seasonData}
            ></Table>
            <Cluster justify='space-between' align='center'>
              <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                <Help state='info'>Total Episodes: {(model as any)?.episodeCount}</Help>
              </Icon>
              {CAN_EDIT && (
                <Popover
                  manualTrigger={true}
                  visible={seasonPopoverOpen.add}
                  onClickOutside={() => setSeasonPopoverOpen({})}
                >
                  <Template label='trigger'>
                    <Button type='primary' onClick={() => setSeasonPopoverOpen({add: true})}>
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <SeasonForm
                      isNew={true}
                      visible={seasonPopoverOpen.add}
                      value={{}}
                      onSave={val => handleSeasonsUpdate(val)}
                      onCancel={() => setSeasonPopoverOpen({})}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Additional Details'>
          <Stack space='medium'>
            <FormItem {...form.notes} onBlur={() => onBlur('notes')} permission={editPermission}>
              <Textarea
                onChange={value => onChange('notes', value)}
                value={model.notes}
                id='notes'
                minHeight='6.25rem'
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
