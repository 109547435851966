import {IValidatorField} from '@pluto-tv/assemble';
import {ISelectedCategory, ISelectedChannel} from 'models/episodes';

import {ISeries, ISeason} from 'models/series';

export const seriesSearchValidator: IValidatorField<ISeries>[] = [
  {
    name: 'name',
    label: 'Series Name',
  },
];

const seriesCreateDetailsShared: IValidatorField<ISeries>[] = [
  {
    name: 'name',
    label: 'Series Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Series Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Series Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'type',
    label: 'Series Type',
    required: true,
    validators: [
      (type: string): string | undefined => {
        if (!type) {
          return 'Series Type is required';
        }
      },
    ],
  },
  {
    name: 'genre',
    label: 'Genre',
    required: true,
    validators: [
      (genre: string): string | undefined => {
        if (!genre) {
          return 'Genre is required';
        }
      },
    ],
  },
  {
    name: 'metadataLanguage',
    label: 'Metadata Language',
    required: true,
    validators: [
      (metadataLanguage: string): string | undefined => {
        if (!metadataLanguage) {
          return 'Metadata Language is required';
        }
      },
    ],
  },
  {
    name: 'summary',
    label: 'Short Description',
    required: true,
    validators: [
      (summary: string): string | undefined => {
        if (!summary || !summary.trim().length) {
          return 'Long Description is required';
        }
      },
    ],
  },
];

export const seriesHeaderValidator: IValidatorField<ISeries>[] = [
  {
    name: 'published',
  },
];

export const seriesDetailsValidator: IValidatorField<ISeries>[] = [
  ...seriesCreateDetailsShared,
  {
    name: 'description',
    label: 'Long Description',
    required: true,
    validators: [
      (description: string): string | undefined => {
        if (!description || !description.trim().length) {
          return 'Long Description is required';
        }
      },
    ],
  },
  {
    name: 'displayName',
    label: 'Display Name',
  },
  {
    name: 'rating',
    label: 'Rating',
  },
  {
    name: 'ratingDescriptors',
    label: 'Rating Descriptors',
  },
  {
    name: 'subGenre',
    label: 'Sub Genre',
  },
  {
    name: 'category',
    label: 'Category',
  },
  {
    name: 'subCategory',
    label: 'Sub Category',
  },
  {
    name: 'notes',
    label: 'Notes',
  },
];

export const seriesSettingsValidator: IValidatorField<ISeries>[] = [
  {
    name: 'regionFilter',
    label: 'Territories',
  },
  {
    name: 'tmsid',
    label: 'TMS ID',
  },
  {
    name: 'tags',
    label: 'Category Tags',
  },
  {
    name: 'seoAliases',
    label: 'SEO Aliases',
  },
  {
    name: 'plutoTvOO',
  },
  {
    name: 'distributeAs',
  },
  {
    name: 'customReferences',
  },
  {
    name: 'availabilityWindows',
  },
  {
    name: 'seasons',
  },
  {
    name: 'slug',
    label: 'Series Slug',
    required: true,
    validators: [
      (slug: string): string | undefined => {
        if (!slug || !slug.trim().length) {
          return 'Series Slug is required';
        }
      },
    ],
  },
];

export const seriesAssetsValidator: IValidatorField<ISeries>[] = [
  {
    name: 'featuredImage',
  },
  {
    name: 'poster',
  },
  {
    name: 'poster16_9',
  },
  {
    name: 'tile',
  },
];

export const seriesSchedulingValidator: IValidatorField<ISeries>[] = [
  {
    name: 'vodCategoryEntries',
    label: 'Vod Category Entries',
  },
  {
    name: 'channels',
    label: 'Channels',
  },
];

const seriesLocation: IValidatorField<ISeries>[] = [
  {
    name: 'activeRegion',
    label: 'Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Region is required';
        }
      },
    ],
  },
];

export const seriesCreateValidator: IValidatorField<ISeries>[] = [
  ...seriesCreateDetailsShared,
  ...seriesLocation,
  {
    name: 'plutoTvOO',
  },
];

export const seriesSeasonValidator: IValidatorField<ISeason>[] = [
  {
    name: 'number',
    label: 'Season Number',
    required: true,
    validators: [
      (seasonNumber: number): string | undefined => {
        if (!seasonNumber || seasonNumber < 1) {
          return 'Season Number must be greater than 0';
        }
      },
    ],
  },
  {
    name: 'synopsis',
    label: 'Season Synopsis',
  },
];

export const seriesVodCategoryValidator: IValidatorField<ISelectedCategory>[] = [
  {
    name: 'id',
  },
  {
    name: 'category',
    label: 'Collection name',
    required: true,
    validators: [
      (category: string): string | undefined => {
        if (!category || !category.trim().length) {
          return 'Category is required';
        }
      },
    ],
  },
  {
    name: 'order',
    label: 'Order',
    required: true,
    validators: [
      (num: number): string | undefined => {
        if (typeof num != 'number') {
          return 'Category order must be a valid number';
        } else if (num < 1) {
          return 'Category order must be a positive number';
        }
      },
    ],
  },
];

export const seriesChannelsValidator: IValidatorField<ISelectedChannel>[] = [
  {
    name: 'id',
  },
  {
    name: 'channel',
    label: 'Channel name',
    required: true,
    validators: [
      (channel: string): string | undefined => {
        if (!channel || !channel.trim().length) {
          return 'Channel is required';
        }
      },
    ],
  },
];
