import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  Copy,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  DateTime,
  FormItem,
  Heading,
  Icon,
  Label,
  Popover,
  Select,
  Spinner,
  Stack,
  TagList,
  Template,
  TextInput,
  Textarea,
  hmsToSeconds,
  secondsToHms,
} from '@pluto-tv/assemble';
import {Player} from '@pluto-tv/assemble-player';
import {orderBy} from 'lodash-es';

import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';

import {useAppPermissions} from 'app/permissions';

import CrudError from 'components/crudError';

import {useGetClipVideo} from 'helpers/useGetClipVideo';
import {useUserModelRatings} from 'helpers/useUserRatings';

import {IClip} from 'models/clips';

import {INestedClipProps} from '../nestedPropsInterface';
import {popoverActionsProps} from 'helpers/popoverActionProps';
import {formatUTCtoFixedLocal} from 'utils/dateUtils';

export default ({
  model,
  // pristineModel,
  setFields,
  onBlur,
  onChange,
  form,
}: INestedClipProps): JSX.Element => {
  const {permissions, ableTo} = useAppPermissions();

  const {
    isLoading: isFetchingStream,
    playerType,
    setActiveClip,
    cc,
    signedUrl,
    streamUrl,
    signedQuery,
    dropFrame,
  } = useGetClipVideo();

  const {
    contentRatings,
    isError: isErrorContentRatings,
    isFetching: isFetchingContentRatings,
  } = useUserModelRatings(model);

  const {data: categories, isFetching: isFetchingGenres, isError: isErrorGenres} = useFindCategoriesQuery();
  const {data: partners, isFetching: isFetchingPartners, isError: isErrorPartners} = useFindPartnersQuery();
  const {data: languages, isFetching: isFetchingLanguages, isError: isErrorLanguages} = useFindLanguagesQuery();

  const [editDurationOpen, setEditDurationOpen] = React.useState(false);
  const [duration, setDuration] = React.useState<string>();
  const [validDuration, setValidDuration] = React.useState(false);

  React.useEffect(() => {
    if (model && !signedUrl) {
      setActiveClip(model as IClip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  React.useEffect(() => {
    if (!duration && model?.duration) {
      setDuration(secondsToHms(model.duration));
    }

    if (duration && duration.length === 8 && secondsToHms(model.duration!) !== duration) {
      setValidDuration(true);
    } else {
      setValidDuration(false);
    }
  }, [model, duration]);

  const changeDuration = (val: string) => {
    if (!val || val.length !== 8) {
      return;
    }

    setDuration(val);
  };

  if (isErrorGenres || isErrorPartners || isErrorLanguages || isErrorContentRatings) {
    return <CrudError />;
  }

  if (isFetchingStream || isFetchingGenres || isFetchingPartners || isFetchingLanguages || isFetchingContentRatings) {
    return (
      <Box fullHeight={true}>
        <Spinner id='detailsSpinner' center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <Stack space='small'>
            <FormItem {...form.name} onBlur={() => onBlur('name')} permission={permissions.CLIP_EDIT}>
              <TextInput onChange={value => onChange('name', value)} value={model.name} id='name' />
            </FormItem>
            <FormItem {...form.summary} onBlur={() => onBlur('summary')} permission={permissions.CLIP_EDIT}>
              <Textarea
                onChange={value => onChange('summary', value)}
                value={model.summary}
                id='summary'
                minHeight='6.25rem'
              />
            </FormItem>
            <FormItem {...form.description} onBlur={() => onBlur('description')} permission={permissions.CLIP_EDIT}>
              <Textarea
                onChange={value => onChange('description', value)}
                value={model.description}
                id='description'
                minHeight='6.25rem'
              />
            </FormItem>
            <FormItem {...form.rating} permission={permissions.CLIP_EDIT}>
              <Select
                placeholder='Please select rating'
                onChange={value => setFields({rating: value.label})}
                value={{label: model.rating || ''}}
                id='rating'
                sortField='weight'
                options={contentRatings}
              />
            </FormItem>
            <FormItem {...form.category} permission={permissions.CLIP_EDIT}>
              <Select
                placeholder='Please select category'
                onChange={value => setFields({category: value?.label})}
                value={{label: model.category || ''}}
                id='category'
                clearable={true}
                options={orderBy(
                  (categories || []).map(category => ({label: category.category})),
                  'label',
                )}
              />
            </FormItem>
            <FormItem {...form.partner} permission={permissions.CLIP_EDIT} onBlur={() => onBlur('partner', false)}>
              <Select
                placeholder='Please select partner'
                onChange={value =>
                  setFields({
                    partner: value?.value,
                    author: {
                      name: value?.label || '',
                    },
                  })
                }
                value={{label: model.partner || '', value: model.partner}}
                id='partner'
                predicate='value'
                clearable={true}
                searchable={true}
                searchPlaceholder='Search for partner'
                onSearch={val =>
                  (partners || [])
                    .filter(partner => partner.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                    .map(partner => ({label: partner.name, value: partner.id})) || []
                }
                options={(partners || []).map(partner => ({label: partner.name, value: partner.id}))}
              />
            </FormItem>
            <FormItem {...form.author} permission={permissions.CLIP_EDIT} onBlur={() => onBlur('author')}>
              <TextInput
                onChange={value => onChange('author', {...model.author, name: value})}
                value={model.author?.name}
                id='author'
              />
            </FormItem>
            <FormItem {...form.language} permission={permissions.CLIP_EDIT}>
              <Select
                onChange={value => setFields({language: value.value})}
                value={{label: model.language || '', value: model.language}}
                id='language'
                predicate='value'
                searchable={true}
                searchPlaceholder='Search for language'
                onSearch={val =>
                  orderBy(
                    (languages || [])
                      .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                      .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                  ) || []
                }
                options={orderBy(
                  (languages || []).map(language => ({label: language.name, value: language.iso639_1})),
                  'label',
                )}
              />
            </FormItem>
            <FormItem {...form.copyright} onBlur={() => onBlur('copyright')} permission={permissions.CLIP_EDIT}>
              <TextInput onChange={value => onChange('copyright', value)} value={model.copyright} id='copyright' />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='Cast'>
          <Stack space='medium'>
            <FormItem {...form.directors} permission={permissions.CLIP_EDIT}>
              <TagList
                value={model.directors}
                onChange={value =>
                  setFields({
                    directors: orderBy([...new Set(value || [])] as string[], director => director.toLowerCase()),
                  })
                }
                id='directors'
              />
            </FormItem>
            <FormItem {...form.actors} permission={permissions.CLIP_EDIT}>
              <TagList
                value={model.actors}
                onChange={value =>
                  setFields({actors: orderBy([...new Set(value || [])] as string[], actor => actor.toLowerCase())})
                }
                id='actors'
              />
            </FormItem>
            <FormItem {...form.writers} permission={permissions.CLIP_EDIT}>
              <TagList
                value={model.writers}
                onChange={value =>
                  setFields({writers: orderBy([...new Set(value || [])] as string[], writer => writer.toLowerCase())})
                }
                id='writers'
              />
            </FormItem>
            <FormItem {...form.producers} permission={permissions.CLIP_EDIT}>
              <TagList
                value={model.producers}
                onChange={value =>
                  setFields({
                    producers: orderBy([...new Set(value || [])] as string[], producer => producer.toLowerCase()),
                  })
                }
                id='producers'
              />
            </FormItem>
            <FormItem {...form.originalReleaseDate} permission={permissions.CLIP_EDIT}>
              <DateTime
                placeholder='Original Release Date'
                value={
                  model.originalReleaseDate
                    ? new Date(formatUTCtoFixedLocal(model.originalReleaseDate, 'MM-dd-yyyy'))
                    : undefined
                }
                onChange={value => setFields({originalReleaseDate: new Date(value as Date).toISOString()})}
                id='originalReleaseDate'
              />
            </FormItem>
            <FormItem {...form.productionCompany} state='disabled' permission={permissions.CLIP_EDIT}>
              <TagList value={model.productionCompany} id='productionCompany' />
            </FormItem>
            <FormItem {...form.productionCountries} state='disabled' permission={permissions.CLIP_EDIT}>
              <TagList value={model.productionCountries} id='productionCompany' />
            </FormItem>
            <FormItem {...form.productionYear} state='disabled' permission={permissions.CLIP_EDIT}>
              <TextInput
                value={model.productionYear && model.productionYear > 0 ? model.productionYear.toString() : ''}
                id='productionYear'
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Quick View'>
          <Stack space='xlarge'>
            {streamUrl ? (
              <Player
                id='preview'
                height='20.625rem'
                playerType={playerType}
                src={streamUrl}
                subtitles={cc}
                license='9CE6F075527140BFF002A0E007D58114U9B800763DCE09ED4B3739AA5CF69B309'
                hlsPlayerQueryString={signedQuery}
                options={{
                  dropFrame,
                  frameRate: {
                    denominator: 1,
                    numerator: model.framerate || 30,
                  },
                }}
              />
            ) : (
              <Box height='20.625rem' />
            )}
            <Cluster align='center' justify='center' space='xxxxxxlarge'>
              <Stack space='xsmall'>
                <Label>Content Status</Label>
                <Heading level='h4'>
                  {model.contentStatus === 'origin' && (
                    <Icon icon='origin' color='infoLight' space='xxsmall'>
                      Origin
                    </Icon>
                  )}
                  {model.contentStatus === 'downloading' && (
                    <Icon icon='downloading' pulse={true} color='infoLight' space='xxsmall'>
                      Downloading
                    </Icon>
                  )}
                  {model.contentStatus === 'uploaded' && (
                    <Icon icon='uploading' color='infoLight' space='xxsmall'>
                      Uploaded
                    </Icon>
                  )}
                  {model.contentStatus === 'processing' && (
                    <Icon icon='inprogress' pulse={true} color='infoLight' space='xxsmall'>
                      Processing
                    </Icon>
                  )}
                  {model.contentStatus === 'ready' && (
                    <Icon icon='check' color='success' space='xxsmall'>
                      Ready
                    </Icon>
                  )}
                  {model.contentStatus === 'failed' && (
                    <Icon icon='error' color='delete' space='xxsmall'>
                      Failed
                    </Icon>
                  )}
                </Heading>
              </Stack>
              <Stack space='xsmall'>
                <Label>Duration</Label>
                <Cluster space='small'>
                  <Heading level='h4'>{secondsToHms(model.duration || 0)}</Heading>
                  {ableTo('CLIP_EDIT') && (
                    <Popover
                      id='durationPopover'
                      manualTrigger={true}
                      visible={editDurationOpen}
                      onClickOutside={() => setEditDurationOpen(false)}
                    >
                      <Template label='trigger'>
                        <span style={{color: '#A8A8A8'}}>
                          <Icon icon='edit' onClick={() => setEditDurationOpen(true)} />
                        </span>
                      </Template>
                      <Template label='popover'>
                        <Box
                          padding={popoverActionsProps.padding}
                          background={popoverActionsProps.background}
                          width={popoverActionsProps.width}
                        >
                          <Stack space='small'>
                            <FormItem label='Duration'>
                              <TextInput
                                id='durationInput'
                                value={duration}
                                fixedPlaceholder='HH:MM:SS'
                                mask='NN:[0-5]N:[0-5]N'
                                onChange={changeDuration}
                              />
                            </FormItem>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Cluster>
                                <Button ghost={true} onClick={() => setEditDurationOpen(false)}>
                                  Cancel
                                </Button>
                                <Button
                                  id='setNewDurationButton'
                                  type='primary'
                                  onClick={() => {
                                    const newDuration = hmsToSeconds(duration!);

                                    setFields({
                                      duration: newDuration,
                                      outPoint: newDuration,
                                    });
                                    setEditDurationOpen(false);
                                  }}
                                  state={!validDuration ? 'disabled' : ''}
                                  permission={permissions.CLIP_EDIT}
                                >
                                  Set New Duration
                                </Button>
                              </Cluster>
                            </Cluster>
                          </Stack>
                        </Box>
                      </Template>
                    </Popover>
                  )}
                </Cluster>
              </Stack>
              {signedUrl && (
                <Heading level='h4'>
                  <Icon icon='download' space='xxsmall' href={signedUrl} linkTarget='_blank' textDecoration={false}>
                    Download
                  </Icon>
                </Heading>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Clip Information'>
          <Stack space='xxlarge'>
            <Stack space='small'>
              <Label>Pluto Avails ID</Label>
              <Copy text={model.plutoAvailsID || 'N/A'} toCopy={model.plutoAvailsID} id='plutoAvailsID' />
            </Stack>
            <Stack space='small'>
              <Label>UUID</Label>
              <Copy text={model.contentUUID || 'N/A'} toCopy={model.contentUUID} id='contentUUID' />
            </Stack>
            <Stack space='small'>
              <Label>Clip Code</Label>
              <Copy text={model.code || 'N/A'} toCopy={model.code} id='code' />
            </Stack>
            <Stack space='small'>
              <Label>Clip ID</Label>
              <Copy text={model.id || 'N/A'} toCopy={model.id} id='copyId' />
            </Stack>
            <Stack space='small'>
              <Label>Uploaded:</Label>
              <Copy text={model.uploaded ? new Date(model.uploaded).toLocaleString() : 'N/A'} />
            </Stack>
            <Stack space='small'>
              <Label>Created At:</Label>
              <Copy text={new Date(model.createdAt!).toLocaleString()} />
            </Stack>
            <Stack space='small'>
              <Label>Updated At:</Label>
              <Copy text={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'} />
            </Stack>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
