import {createApi} from '@reduxjs/toolkit/query/react';
import {IChannelQueueQuery} from 'models/channelQueue';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {IListPayload} from 'models/generic';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';

const getParamsFromModel = (
  searchModel: Partial<Omit<IChannelQueueQuery, 'updatedAt'> & {start: number; stop: number}> = {},
) => {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;

    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    return [...acc, {[key]: encodeURIComponent(value)}];
  }, [] as {[key in keyof Partial<IChannelQueueQuery>]: string | number}[]);
};

export const channelQueueApi = createApi({
  reducerPath: 'channelQueueApi',
  tagTypes: ['ChannelQueue', 'ChannelQueueLibrary', 'ChannelQueueQueue'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    findQueue: builder.query<IListPayload<IChannelCatalogItem>, Partial<IChannelQueueQuery> & {channelId: string}>({
      query: ({
        channelId,
        offset = 0,
        limit = 25,
        sort = ['series.name:asc', 'season:asc', 'number:asc'],
        updatedAt,
        ...restParams
      }) => {
        const paramsString = getParamsFromModel({offset, limit, sort, ...updatedAt, ...restParams})
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');

        const searchParams = new URLSearchParams(`${paramsString}&channelId=${channelId}`);
        searchParams.append('status', 'completed');
        searchParams.append('status', 'on air');

        return {
          url: `channels/${channelId}/queue?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: Infinity,
      providesTags: ['ChannelQueue', 'ChannelQueueLibrary'],
    }),
    findQueueDuration: builder.query<{duration: number}, {channelId: string}>({
      query: ({channelId}) => {
        return {
          url: `channels/${channelId}/queue-duration`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['ChannelQueue', 'ChannelQueueLibrary'],
    }),
  }),
});

export const {useFindQueueQuery, useLazyFindQueueQuery, useFindQueueDurationQuery} = channelQueueApi;
