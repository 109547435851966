import * as React from 'react';
import useSearchHubData, {ISearchData} from 'components/hooks/hub/useSearchHubData';
import HubList from 'components/hubList/HubList';
import {HubLazyLoadProvider} from 'components/hubList/providers/HubLazyLoadProvider';
import {useLazyFindQuery, useLazySearchQuery} from 'features/hubConfigs/hubConfigsApi';
import {IHubConfig} from 'models/hubConfigs';
import {IHubConfigSearch} from 'models/hubConfigs';
import {HubSearchLazyLoadProvider} from 'components/hubList/providers/HubSearchLazyLoadProvider';

export interface IHubConfigListProps {
  actionsCol?: boolean;
  addNewHubConfig?: boolean;
  checkboxCol?: boolean | 'multiple';
  inModal?: boolean;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  onSelect?: () => void;
  isSearchExpanded?: boolean;
}

export default (props: IHubConfigListProps): React.ReactElement => {
  return (
    <HubLazyLoadProvider
      useProvider={() => useSearchHubData<ISearchData<IHubConfig>, IHubConfigSearch>(useLazyFindQuery as any)}
    >
      <HubSearchLazyLoadProvider
        useProvider={() => useSearchHubData<ISearchData<IHubConfig>, IHubConfigSearch>(useLazySearchQuery as any)}
      >
        <HubList {...props} />
      </HubSearchLazyLoadProvider>
    </HubLazyLoadProvider>
  );
};
