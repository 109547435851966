import {RootState} from 'app/store';
import {useLazyFindByIdQuery} from 'features/carouselConfigs/carouselConfigsApi';
import {uniq, uniqBy} from 'lodash-es';
import {ICarouselConfig} from 'models/carouselConfigs';
import {IHubCarousel} from 'models/hubConfigs';
import React from 'react';
import {useSelector} from 'react-redux';

interface IUseHubCarouselItemsCache {
  cachedCarouselItems: ICarouselConfig[];
  isError: string | undefined;
  isLoading: boolean;
}

export const useHubCarouselItemsCache = (hubCarousels: IHubCarousel[]): IUseHubCarouselItemsCache => {
  const [isError, setIsError] = React.useState<string | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [fetchCarouselConfigs] = useLazyFindByIdQuery();

  const fetchCarousels = React.useCallback(
    async (hubCarousels: IHubCarousel[]) => {
      try {
        const carouselIds = uniq(hubCarousels.map(ca => ca.id));
        const promises = carouselIds.map(carouselId => fetchCarouselConfigs(carouselId).unwrap());
        return await Promise.all(promises);
      } catch (error: any) {
        setIsError('Error fetching carousels, please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [fetchCarouselConfigs],
  );

  const carouselItemsCache = useSelector((state: RootState) => state.carouselConfigsApi.queries);

  const cachedCarouselItems: ICarouselConfig[] = React.useMemo(() => {
    if (carouselItemsCache) {
      const itemsFound = Object.values(carouselItemsCache).map(
        (value: any) => value.status === 'fulfilled' && value.data,
      );
      return uniqBy([...(itemsFound || [])], 'id') as ICarouselConfig[];
    }

    return [];
  }, [carouselItemsCache]).filter(Boolean);

  const cachedCarouselIds = cachedCarouselItems.map((item: ICarouselConfig) => item.id);

  React.useEffect(() => {
    const idsToLookup = hubCarousels?.map(hc => hc.id).filter(id => !cachedCarouselIds?.includes(id));

    if (idsToLookup.length > 0) {
      fetchCarousels(hubCarousels);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCarousels, hubCarousels]);

  return {
    cachedCarouselItems,
    isError,
    isLoading,
  };
};
