import * as React from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  Icon,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  Divider,
  useValidateForm,
  Notification,
  Paragraph,
  Dialog,
  secondsToHms,
  useCancellableSummon,
} from '@pluto-tv/assemble';
import {capitalize, cloneDeep, isEqual} from 'lodash-es';

import {useFindByIdQuery, useIsValidQuery, useUpdateMutation} from 'features/episodes/episodesApi';
import {
  useInsertMutation as useInsertCategoryEntryMutation,
  useUpdateMutation as useUpdateCategoryEntryMutation,
  useDeleteMutation as useDeleteCategoryEntryMutation,
} from 'features/vodCategoryEntries/vodCategoryEntriesApi';

import ConfirmRouteChange from 'components/confirmRouteChange';
import CrudError from 'components/crudError';
import contentRoutes from 'routes/content.routes';
import {IEpisode, IEpisodeSource} from 'models/episodes';

import {msToTimecode} from 'helpers/msToTimecode';
import {useUserRegionModel} from 'helpers/useUserRegions';
import {mapPopulatedEpisodeToEpisode} from 'helpers/mapPopulatedEpisode';

import EpisodeDetails from './details';
import EpisodeSettings from './settings';
import EpisodeScheduling from './scheduling';
import EpisodeVideo, {IEpisodeVideoRef} from './video';
import EpisodeAssets from './assets';

import {
  episodeDetailsValidator,
  episodeSettingsValidator,
  episodeVideoValidator,
  episodeSchedulingValidator,
  episodeAssetsValidator,
  episodeGlobalValidator,
} from '../validators';
import {useEpisodePermissions} from '../permissions/useEpisodePermissions';
import Confirmation from '../../../../components/saveConfirmation/confirmation';
import NotAuthorized from 'components/notAuthorized';
import {getSeriesIdFromUrlPath} from 'helpers/seriesIdFromPath';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';

const episodeTabs = ['details', 'settings', 'scheduling', 'video', 'assets'] as const;

export type TEpisodeTabs = (typeof episodeTabs)[number];

const getActiveTab = (pathname: string): TEpisodeTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as TEpisodeTabs;

  if (episodeTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'details';
};

export default (): JSX.Element => {
  const params: {id: string; episodeId?: string} = useParams();
  const history = useHistory();
  const location = useLocation();

  const [summon] = useCancellableSummon();

  const activeTab: TEpisodeTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [isSaveOpen, setIsSaveOpen] = React.useState(false);
  const [episodeId, setEpisodeId] = React.useState<string>('');

  const [hasValidated, setHasValidated] = React.useState(false);

  const [showWarnings, setShowWarnings] = React.useState<boolean>(false);
  const [episodeWarnings, setEpisodeWarnings] = React.useState<string[]>([]);

  const [hasOutPointWarning, setHasOutPointWarning] = React.useState(false);
  const [hasAdPodWarning, setHasAdPodWarning] = React.useState(false);
  const [hasAckWarning, setHasAckWarning] = React.useState(false);
  const [hasInOutPointWarning, setHasInOutPointWarning] = React.useState(false);

  const {
    data: episode,
    isError: isEpisodeError,
    error: episodeError,
    isLoading: isEpisodeFetching,
    refetch: refetchEpisodeById,
  } = useFindByIdQuery(params.episodeId ?? params.id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: episodeValidationMessage,
    isFetching: isEpisodeValidationMessageFetching,
    refetch: refetchEpisodeValidationMessage,
  } = useIsValidQuery(params.episodeId ?? params.id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateEpisode] = useUpdateMutation();
  const [insertVodCategoryEntry] = useInsertCategoryEntryMutation();
  const [updateVodCategoryEntry] = useUpdateCategoryEntryMutation();
  const [deleteVodCategoryEntry] = useDeleteCategoryEntryMutation();

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IEpisode>([
    ...episodeGlobalValidator,
    ...episodeDetailsValidator,
    ...episodeSettingsValidator,
    ...episodeVideoValidator,
    ...episodeSchedulingValidator,
    ...episodeAssetsValidator,
  ]);

  const {CAN_VIEW, editPermission, CAN_EDIT} = useEpisodePermissions(episode);

  const canViewRegion = useUserRegionModel(episode);

  const videoTabRef = React.useRef<IEpisodeVideoRef>(null);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(contentRoutes.paths.episodeListPage);
    }
  }, [isLeaving, history]);

  // Handler for episode initial load or after save
  React.useEffect(() => {
    if (episode) {
      const episodeModel = cloneDeep(episode);

      if (episodeModel.ratingDescriptors) {
        episodeModel.ratingDescriptors = episodeModel.ratingDescriptors.slice().sort((a, b) => (a > b ? 1 : -1));
      }

      setModel(episodeModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episode]);

  React.useEffect(() => {
    if (!isEpisodeValidationMessageFetching && !isEpisodeFetching) {
      setHasValidated(true);
    }
  }, [isEpisodeValidationMessageFetching, isEpisodeFetching]);

  React.useEffect(() => {
    if (dirtyFields.sources) {
      setHasAdPodWarning(!adPodsMatchBreak());
      setHasOutPointWarning(!checkOutPoints());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, model]);

  React.useEffect(() => {
    setHasAckWarning(false);
  }, [isSaveOpen]);

  React.useEffect(() => {
    if (params) {
      setEpisodeId(params.episodeId ?? params.id);
    }
  }, [params]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  // shortcut to save episode
  useKeyboardShortcut('ctrl + s, command + s', event => {
    event.preventDefault();

    if (CAN_EDIT && activeTab === 'video') {
      saveEpisode();
    }
  });

  const saveVodCategories = async () => {
    setIsSaving(true);
    try {
      await Promise.all(
        (model.vodCategoryEntries || []).map(async voc => {
          switch (voc.changeType) {
            case 'D':
              await deleteVodCategoryEntry({
                id: voc.id,
                episode: voc.episode,
              }).unwrap();
              break;
            case 'U':
              await updateVodCategoryEntry({
                id: voc.id,
                vodCategoryEntry: {
                  id: voc.id,
                  episode: voc.episode,
                  vodCategory: voc.vodCategory.id,
                  order: voc.order,
                },
              }).unwrap();
              break;
            case 'I':
              await insertVodCategoryEntry({
                episode: voc.episode,
                order: voc.order,
                vodCategory: voc.vodCategory.id,
              }).unwrap();
              break;
          }
        }),
      );
      await refetchEpisodeValidationMessage();
      setModel(cloneDeep(model));
      Toast.success('Success', 'Episode Updated');
    } catch (e: any) {
      Toast.error(
        'Error',
        e?.data?.message.length > 0
          ? `Failed to save episode. ${capitalize(e?.data?.message)}`
          : 'Failed to save episode. Please try again',
      );
    } finally {
      setIsSaving(false);
    }
  };

  const saveEpisode = async (): Promise<boolean> => {
    setIsSaving(true);
    setIsSaveOpen(false);

    // Secondary validation...
    if (!Number.isFinite(model.duration) || !Number.isFinite(model.allotment)) {
      Toast.error('Duration and Allotment values are required');
      setIsSaving(false);
      return false;
    }

    if (secondsToHms(model.duration || 0) !== secondsToHms(model.allotment || 0) && model.published) {
      Toast.error('Episode duration must match allotment');
      setIsSaving(false);
      return false;
    }

    if ((dirtyFields.duration || dirtyFields.allotment) && model.nextTimeline) {
      const now = new Date();
      const nextStart = new Date(model.nextTimeline.start);

      if (nextStart > now) {
        Toast.error(
          'The duration of this episode cannot be changed because it is currently scheduled in a channel timeline. Please remove this episode from the channel timeline in order to save duration changes',
        );
        setIsSaving(false);
        return false;
      }
    }

    try {
      await saveEpisodeWithWarnings(false, true);
    } catch (e) {
      return false;
    }

    return true;
  };

  const saveEpisodeWithWarnings = async (acceptWarnings: boolean, throwError?: boolean) => {
    try {
      const episodeToSave = mapPopulatedEpisodeToEpisode(model);
      await updateEpisode({
        id: episodeId,
        episode: episodeToSave,
        fields: Object.keys(dirtyFields),
        acceptWarnings,
      }).unwrap();
      Toast.success('Success', 'Episode Updated');
      await refetchEpisodeValidationMessage();
    } catch (e: any) {
      if (e?.data?.warnings) {
        setEpisodeWarnings(e.data.warnings as string[]);
        setShowWarnings(true);
      } else {
        Toast.error(
          'Error',
          e?.data?.message.length > 0
            ? `Failed to save episode. ${capitalize(e?.data?.message)}`
            : 'Failed to save episode. Please try again',
        );
      }

      if (throwError) {
        setIsSaving(false);
        throw new Error('error saving');
      }
    } finally {
      setIsSaving(false);
    }
  };

  // We don't care about promotional clips
  const checkPodBreakpoints = (source: IEpisodeSource): boolean => {
    const {adPods, clip} = source;

    if (clip.promotional) {
      return true;
    }

    const framerate = clip.framerate || 30;

    // We're filtering out 0 values. We don't need to trigger a transcode on a 0 timestamp
    const pods = (adPods || []).filter(a => a.startAt > 0).map(a => msToTimecode(a.startAt, framerate));
    const breakpoints = (clip.breakpoints || []).filter(b => b > 0).map(b => msToTimecode(b, framerate));

    return isEqual(pods, breakpoints);
  };

  const handleWarningsModalCancel = () => {
    setShowWarnings(false);
  };

  const handleWarningsModalAccept = async () => {
    setShowWarnings(false);
    await saveEpisodeWithWarnings(true);
  };

  const ackWarning = () => {
    setHasAckWarning(true);

    if (!hasAdPodWarning) {
      saveEpisode();
    }
  };

  const saveClipsAndEpisode = async () => {
    setIsSaving(true);

    const episodeSources = cloneDeep(model.sources || []);

    try {
      const res = await saveEpisode();

      if (!res) {
        return;
      }

      const clipPromises: Promise<void>[] = [];

      episodeSources.forEach(source => {
        if (!checkPodBreakpoints(source)) {
          const {clip} = source;
          const clipId = clip.id || clip._id;

          const breakpoints = (source.adPods || []).map(a => a.startAt);

          clipPromises.push(
            summon.put(`/clips/${clipId}/breakpoints`, {
              breakpoints,
              id: clipId,
            }),
          );
        }
      });

      await Promise.all(clipPromises);
      videoTabRef.current?.resetDirtySources();
    } catch (e) {
      Toast.error('Error', (e as any).message);
    }
  };

  const saveData = async (ev: any) => {
    if (ev) {
      ev.preventDefault();
    }

    if (hasOutPointWarning || hasAdPodWarning) {
      setIsSaveOpen(true);
      return;
    }

    if (dirtyFields.vodCategoryEntries) {
      await saveVodCategories();
      await refetchEpisodeById();
    } else {
      await saveEpisode();
    }
  };

  const checkOutPoints = () => (model.sources || []).every(s => Math.floor(s.outPoint) <= Math.ceil(s.clip.duration));
  const adPodsMatchBreak = () => (model.sources || []).every(s => checkPodBreakpoints(s));

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleStatusOpen = React.useCallback(() => {
    setStatusOpen(prev => !prev);
  }, []);

  const handleTabChange = (activeTab: string) => {
    const serieId = getSeriesIdFromUrlPath();

    const contentPath = !!serieId ? 'seriesEditEpisodes' : 'episodeEdit';

    const tabsPathAction = {
      details: contentRoutes.paths[`${contentPath}DetailsPage`],
      settings: contentRoutes.paths[`${contentPath}SettingsPage`],
      scheduling: contentRoutes.paths[`${contentPath}SchedulingPage`],
      video: contentRoutes.paths[`${contentPath}VideoPage`],
      assets: contentRoutes.paths[`${contentPath}AssetsPage`],
    };

    const route = !!serieId
      ? tabsPathAction[activeTab].replace(':id', serieId).replace(':episodeId', episodeId)
      : tabsPathAction[activeTab].replace(':id', episodeId);

    history.push(route);
  };

  if (isEpisodeError) {
    return <CrudError error={episodeError} />;
  }

  if (isEpisodeFetching || (!hasValidated && isEpisodeValidationMessageFetching)) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (!CAN_VIEW || !canViewRegion) {
    return <NotAuthorized />;
  }

  return (
    <>
      {showWarnings && (
        <Confirmation
          isSaving={isSaving}
          onCancel={handleWarningsModalCancel}
          onConfirm={handleWarningsModalAccept}
          warnings={episodeWarnings}
        />
      )}
      <Dialog isOpen={isSaveOpen} onClose={() => setIsSaveOpen(false)} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>
            <Icon icon='warning' color='warning' space='xxsmall'>
              Confirm Changes
            </Icon>
          </Heading>
        </Template>
        <Template label='body'>
          <Stack space='large'>
            <Paragraph color='secondary'>Some of your changes could have unintended consequences:</Paragraph>
            {hasOutPointWarning && !hasAckWarning ? (
              <Paragraph color='secondary'>
                One of the clips on your episode have an out point greater than the duration of the clip. Please double
                check to make sure this is supported by the clip.
              </Paragraph>
            ) : (
              <Stack space='large'>
                <Paragraph color='secondary'>
                  The adpods on your episode are different from the breakpoints on your clips. Frame-accurate
                  ad-insertion will no longer work with this configuration.
                </Paragraph>
                <Paragraph color='secondary'>
                  Press <b>Frame Accurate Save</b> to update the ad-breaks on this episode&apos;s clips and enable
                  frame-accurate ad-insertion. This will trigger a clip re-transcode.
                </Paragraph>
                <Paragraph color='secondary'>
                  Press <b>Non-Frame Accurate Save</b> to save this episode without making breakpoint changes on the
                  clips.
                </Paragraph>
                <Paragraph color='secondary'>
                  If you&apos;re not sure what this is, Press <b>Non-Frame Accurate Save</b>.
                </Paragraph>
              </Stack>
            )}
            <Paragraph color='secondary'>Are you sure you want to save?</Paragraph>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <Button type='solid' state={isSaving ? 'disabled' : ''} size='small' onClick={() => setIsSaveOpen(false)}>
              Discard Changes
            </Button>
            {hasOutPointWarning && !hasAckWarning ? (
              <Cluster space='small'>
                <Button
                  ghost={true}
                  state={isSaving ? 'disabled' : ''}
                  size='small'
                  onClick={() => setIsSaveOpen(false)}
                >
                  Review Changes
                </Button>
                <Button size='small' state={isSaving ? 'thinking' : ''} type='primary' onClick={ackWarning}>
                  Save and Proceed
                </Button>
              </Cluster>
            ) : (
              <Cluster space='small'>
                <Button ghost={true} state={isSaving ? 'disabled' : ''} size='small' onClick={saveEpisode}>
                  Non-Frame Accurate Save
                </Button>
                <Button size='small' state={isSaving ? 'thinking' : ''} type='primary' onClick={saveClipsAndEpisode}>
                  Frame Accurate Save
                </Button>
              </Cluster>
            )}
          </Cluster>
        </Template>
      </Dialog>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={(ev: any) => saveData(ev)}
        hasFlaggedFields={(hasOutPointWarning && !hasAckWarning) || hasAdPodWarning}
        isValid={formState.isValid}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='small'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  Episode: {pristineModel.name} | S{pristineModel.season}: E{pristineModel.number}
                </Heading>
                <Cluster align='flex-start' space='medium' wrap={false}>
                  <Cluster space='xxsmall' wrap={false}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Active Region:
                    </Heading>
                    <Heading level='h5' color='primary'>
                      {pristineModel.activeRegion?.toUpperCase()}
                    </Heading>
                  </Cluster>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Button href={`/series/${pristineModel.series?.id}`} type='primary'>
                  View Series
                </Button>
                <Popover
                  manualTrigger={true}
                  visible={statusOpen}
                  onClickOutside={() => setStatusOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => toggleStatusOpen()}
                      permission={editPermission}
                    >
                      {model.status === 'completed' ? (
                        <Icon space='xxxsmall' icon='check' color='success'>
                          Completed
                        </Icon>
                      ) : model.status === 'on air' ? (
                        <Icon space='xxxsmall' icon='onair' color='warning'>
                          On Air
                        </Icon>
                      ) : model.status === 'in progress' ? (
                        <Icon space='xxxsmall' icon='inprogress' pulse={true} color='infoLight'>
                          In Progress
                        </Icon>
                      ) : (
                        <Icon space='xxxsmall' icon='archive' color='neutral'>
                          Archived
                        </Icon>
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Icon
                        space='xxxsmall'
                        icon='check'
                        color='success'
                        id='completedButton'
                        onClick={() => {
                          if (model.status !== 'in progress') {
                            Toast.warning('Episode must be In Progress before you can move it to Complete');
                            return;
                          }

                          if (!model.published) {
                            Toast.warning('Episode must be published before you can move it to Complete');
                            return;
                          }

                          setFields({status: 'completed'});
                          toggleStatusOpen();
                        }}
                      >
                        Completed&nbsp;&nbsp;
                      </Icon>
                      <Icon
                        space='xxxsmall'
                        icon='inprogress'
                        id='inprogressButton'
                        color='infoLight'
                        onClick={() => {
                          setFields({status: 'in progress'});
                          toggleStatusOpen();
                        }}
                      >
                        In Progress
                      </Icon>
                      <Icon
                        space='xxxsmall'
                        icon='archive'
                        color='neutral'
                        id='archivedButton'
                        onClick={() => {
                          if (model.published) {
                            Toast.warning('Episode must be unpublished before you can archive it');
                            return;
                          }

                          setFields({status: 'archived'});
                          toggleStatusOpen();
                        }}
                      >
                        Archived
                      </Icon>
                    </Stack>
                  </Template>
                </Popover>
                <Popover
                  manualTrigger={true}
                  visible={publishOpen}
                  onClickOutside={() => setPublishOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={editPermission}
                    >
                      {model.published ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Status
                        id='unpublishedButton'
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({
                            published: false,
                            ...(model.status === 'completed' && {
                              status: 'archived',
                            }),
                          });
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        id='publishedButton'
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({
                            published: true,
                            ...(['in progress', 'archived'].some(s => s === model.status) && {
                              status: 'completed',
                            }),
                          });
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
              </Cluster>
            </Cluster>
            {!isEpisodeValidationMessageFetching && episodeValidationMessage !== null && (
              <Notification type='error'>{capitalize(episodeValidationMessage)}</Notification>
            )}
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster justify='center' space='small'>
              <Cluster buttonGroup={true} wrap={false}>
                <Button active={activeTab === 'details'} onClick={() => handleTabChange('details')}>
                  Details
                </Button>
                <Button active={activeTab === 'settings'} onClick={() => handleTabChange('settings')}>
                  Settings
                </Button>
                <Button active={activeTab === 'scheduling'} onClick={() => handleTabChange('scheduling')}>
                  Scheduling
                </Button>
                <Button active={activeTab === 'video'} onClick={() => handleTabChange('video')}>
                  Video
                </Button>
                <Button active={activeTab === 'assets'} onClick={() => handleTabChange('assets')}>
                  Assets
                </Button>
              </Cluster>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'details' && (
            <EpisodeDetails
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'settings' && (
            <EpisodeSettings
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'scheduling' && (
            <EpisodeScheduling
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'video' && (
            <EpisodeVideo
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              ref={videoTabRef}
              setHasInOutPointWarning={setHasInOutPointWarning}
            />
          )}
          {activeTab === 'assets' && (
            <EpisodeAssets
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={cancelHandler} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={
                    !formState.isValid || !formState.isDirty || hasInOutPointWarning
                      ? 'disabled'
                      : isSaving
                      ? 'thinking'
                      : ''
                  }
                  onClick={(ev: any) => saveData(ev)}
                  id='save'
                  permission={editPermission}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
