import * as React from 'react';
import {Table, ITableCol, Status} from '@pluto-tv/assemble';
import {IFeaturedGroup} from 'models/featuredGroup';
import {useUserRegions} from 'helpers/useUserRegions';
import {RowState} from 'components/featuredGroupList/components/RowState';
import {TableActions} from 'components/tableActions';
import {useAppPermissions} from 'app/permissions';
import {formatUTCtoFixedLocal} from 'utils/dateUtils';
import FeaturedGroupsListPopover from './FeaturedGroupsListPopover';
import {tableDateTimeFormat as dateTimeFormat} from 'components/featuredGroupList/utils/dateUtils';

export const featuredGroupsTableId = 'featuredGroupsTable';

export interface IFeaturedGroupTableProps {
  onEdit: (groupId: string) => void;
  onDelete: (groupId: string) => void;
  onCopy: (group: IFeaturedGroup) => void;
  onSelectRows?: (rows: IFeaturedGroup[]) => void;
  selectedRows?: IFeaturedGroup[];
  rows: IFeaturedGroup[];
  isFetching: boolean;
}

const FeaturedGroupsTable = ({
  onEdit,
  onDelete,
  onCopy,
  selectedRows,
  onSelectRows,
  rows,
  isFetching,
}: IFeaturedGroupTableProps): React.ReactElement => {
  const {ableTo} = useAppPermissions();

  const canDelete = ableTo('FEATURED_GROUPS_DELETE');
  const canCreate = ableTo('FEATURED_GROUPS_CREATE');
  const canEdit = ableTo('FEATURED_GROUPS_EDIT');

  const {isFetching: isActiveRegionsFetching} = useUserRegions();

  const columns: ITableCol<IFeaturedGroup>[] = React.useMemo(() => {
    return [
      {
        label: '',
        colWidth: '0.625rem',
        zeroRightPadding: true,
        transform: row => <RowState row={row} />,
      },
      {
        label: 'Day',
        field: 'day',
        colMinWidth: '7rem',
      },
      {
        label: 'Start Date',
        colMinWidth: '12rem',
        transform: row => formatUTCtoFixedLocal(row.start, dateTimeFormat),
      },
      {
        label: 'Group Name',
        colMinWidth: '16rem',
        transform: row => {
          return <FeaturedGroupsListPopover row={row} onEdit={onEdit} />;
        },
      },
      {
        label: 'Active Region',
        colMinWidth: '9rem',
        transform: row => row?.activeRegion?.toUpperCase(),
      },
      {
        label: '# of Channels',
        colMinWidth: '9rem',
        transform: row => row?.contentItems?.length || 0,
      },
      {
        label: 'Published',
        colMinWidth: '9rem',
        transform: row => (
          <Status label={row.published ? 'Published' : 'Unpublished'} state={row.published ? 'success' : 'neutral'} />
        ),
      },
      ...(canEdit || canCreate || canDelete
        ? [
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <TableActions
                  row={row}
                  icons={canEdit ? ['edit'] : []}
                  deleteOption={canDelete && !row.published}
                  duplicateOption={canCreate}
                  onClick={(row, icon) => {
                    switch (icon) {
                      case 'edit':
                        onEdit(row.id);
                        break;
                      case 'copy':
                        onCopy(row);
                        break;
                      case 'delete':
                        onDelete(row.id);
                        break;
                      default:
                    }
                  }}
                />
              ),
            } as ITableCol<IFeaturedGroup>,
          ]
        : []),
    ];
  }, [canCreate, canDelete, canEdit, onCopy, onDelete, onEdit]);

  const baseTableProps = {
    id: featuredGroupsTableId,
    loading: isActiveRegionsFetching || isFetching,
    fixedHeader: true,
    wrapContent: true,
    loadingMsg: 'Loading Featured Group List',
    emptyMsg: 'No featured groups found.',
    cols: columns,
    rows: rows,
    rowStatus: (row: any) => row.state?.state,
  };

  const tableProps = canDelete
    ? {
        ...baseTableProps,
        selectable: 'multiple' as const,
        selected: selectedRows,
        onSelect: onSelectRows,
        predicate: 'id' as keyof IFeaturedGroup,
      }
    : baseTableProps;

  return <Table {...tableProps} />;
};

FeaturedGroupsTable.displayName = 'FeaturedGroupsTable';
export default FeaturedGroupsTable;
