import * as React from 'react';

import {
  Box,
  Button,
  Cluster,
  Carousel,
  Click,
  Icon,
  FormItem,
  Heading,
  Select,
  TextInput,
  Paragraph,
  Popover,
  Stack,
  Template,
  TSize,
  useValidateForm,
  ISelectOption,
} from '@pluto-tv/assemble';

// import {ICarouselConfig} from 'models/carouselConfigs';
import DeleteConfirmation from 'components/deleteConfirmation';
import {ICarouselBlockItem, IHubCarousel} from 'models/hubConfigs';
import {hubCarouselConfigsValidator} from 'views/programming/hubManager/hubs/validators';
import programmingRoutes from 'routes/programming.routes';
import {popoverActionsProps} from 'helpers/popoverActionProps';
import {getTileSizeOptions} from 'components/carouselList/components/helpers/carouselServiceOptions';

const tileSizeOptions = getTileSizeOptions();
import {getPrefixedUrl} from 'routes';

export enum CarouselBlockPosition {
  First,
  Last,
  Other,
}

export interface ICarouselBlockProps {
  index?: number;
  imageBorderRadius?: TSize;
  imageWidth?: TSize;
  imageHeight?: TSize;
  height?: TSize;
  onArrowUpClick?: () => void;
  onArrowDownClick?: () => void;
  onDragStart?: (event: React.DragEvent<any>) => void;
  onDragOver?: (event: React.DragEvent<any>) => void;
  onRemove?: (carouselId: string) => void;
  onUpdate?: (carousel: Partial<IHubCarousel>) => void;
  carousel: IHubCarousel;
  truncateItemTextOnLine?: number;
  canEdit: boolean;
  position: CarouselBlockPosition;
}

const CarouselBlock = React.memo(
  ({
    index,
    imageBorderRadius,
    imageWidth = '12.125rem',
    imageHeight = '6.6875rem',
    height = '9.5rem',
    onArrowUpClick,
    onArrowDownClick,
    onDragStart,
    onDragOver,
    onRemove,
    onUpdate,
    carousel,
    truncateItemTextOnLine = 2,
    canEdit,
    position,
  }: ICarouselBlockProps) => {
    const [editPopoverOpen, setEditPopoverOpen] = React.useState(false);
    const [isRemoveCarouselOpen, setIsRemoveCarouselOpen] = React.useState(false);

    const {
      form,
      model,
      onBlur,
      onChange,
      setModel,
      setFields,
      state: formState,
    } = useValidateForm<IHubCarousel>(hubCarouselConfigsValidator, 'immediate');

    const titleUrl = React.useMemo(
      () => getPrefixedUrl(programmingRoutes.paths.carouselEditDetailsPage.replace(':id', carousel.id)),
      [carousel.id],
    );
    const items = React.useMemo(() => carousel.tiles as ICarouselBlockItem[], [carousel.tiles]);

    React.useEffect(() => {
      setModel(carousel);
    }, [carousel, setModel]);

    const tiles: ICarouselBlockItem[] = React.useMemo(() => {
      const tiles = carousel.tiles as ICarouselBlockItem[];

      if (carousel.apiService === 'mediacatalog#vod-main-categories') {
        if (tiles.length) {
          return tiles.map(tile => ({imageSrc: '/pluto-mainCategory.png', text: tile.text}));
        }
        return Array.from({length: 10}, () => ({imageSrc: '/pluto-mainCategory.png', text: ''}));
      }

      if (tiles.length) {
        return tiles;
      }

      //Carousels that return no tile metadata should display up to 10 tiles with “Pluto TV Automated” image
      return Array.from({length: 10}, () => ({imageSrc: '/pluto-automated.png', text: ''}));
    }, [carousel.apiService, carousel.tiles]);

    const handleRemove = () => {
      onRemove?.(carousel.id);
    };

    const handleOnItemClick = (_itemIndex: number, item: ICarouselBlockItem) => {
      if (item.linkToRedirect) {
        window.open(item.linkToRedirect);
      }
    };

    const onUpdateConfirm = () => {
      if (onUpdate) {
        onUpdate(model);
      }
      setEditPopoverOpen(false);
    };

    const onUpdateCancel = () => {
      setModel(carousel);
      setEditPopoverOpen(false);
    };

    return (
      <Box onDragOver={onDragOver}>
        <Stack space='xsmall'>
          <Cluster justify='space-between' space='small' wrap={false}>
            <Cluster fullWidth={true} space='xxsmall' wrap={false}>
              {canEdit && onDragStart && <Icon icon='drag' draggable={true} onDragStart={onDragStart} />}
              {titleUrl ? (
                <Click
                  size='medium'
                  color='primary'
                  hoverColor='primaryLight'
                  href={titleUrl}
                  truncate={true}
                  truncateBackgroundHover='pewter'
                  hrefTarget='_blank'
                  rel='opener'
                  id='carouselTitle'
                >
                  {index}. {carousel.displayName}
                </Click>
              ) : (
                <Heading level='h4' truncate={true} truncateBackgroundHover='pewter'>
                  {index}. {carousel.displayName}
                </Heading>
              )}
            </Cluster>
            <Cluster align='center' space='xxlarge' wrap={false}>
              <Cluster align='center' space='small' wrap={false}>
                {canEdit && onArrowUpClick && (
                  <Icon
                    onClick={onArrowUpClick}
                    icon='directionup'
                    disabled={position === CarouselBlockPosition.First}
                  />
                )}
                {canEdit && onArrowDownClick && (
                  <Icon
                    onClick={onArrowDownClick}
                    icon='directiondown'
                    disabled={position === CarouselBlockPosition.Last}
                  />
                )}
                {canEdit && (
                  <Popover
                    manualTrigger={true}
                    visible={editPopoverOpen}
                    onClickOutside={() => setEditPopoverOpen(false)}
                  >
                    <Template label='trigger'>
                      <Icon icon='edit' onClick={() => setEditPopoverOpen(true)} />
                    </Template>
                    <Template label='popover'>
                      <Box
                        padding={popoverActionsProps.padding}
                        background={popoverActionsProps.background}
                        width={popoverActionsProps.width}
                      >
                        <form id='carouselForm'>
                          <Stack space='small'>
                            <FormItem {...form.name} child='Paragraph' permission='disabled'>
                              <Paragraph id='hubName'>{model.name || 'N/A'}</Paragraph>
                            </FormItem>
                            <FormItem {...form.displayName} onBlur={() => onBlur('displayName')}>
                              <TextInput
                                id='displayName'
                                value={model.displayName}
                                onChange={val => onChange('displayName', val)}
                              />
                            </FormItem>
                            <FormItem label='Tile Size'>
                              <Select
                                id='tileSize'
                                predicate='value'
                                value={
                                  {
                                    label: model.tileSize,
                                    value: model.tileSize,
                                  } as ISelectOption
                                }
                                options={tileSizeOptions}
                                onChange={value => {
                                  setFields?.({tileSize: (value as ISelectOption)?.value});
                                }}
                              />
                            </FormItem>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Cluster space='xxsmall'>
                                <Button ghost={true} onClick={() => onUpdateCancel()}>
                                  Cancel
                                </Button>
                                <Button
                                  type='primary'
                                  onClick={onUpdateConfirm}
                                  state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                                  permission={canEdit ? '' : 'disabled'}
                                >
                                  Update
                                </Button>
                              </Cluster>
                            </Cluster>
                          </Stack>
                        </form>
                      </Box>
                    </Template>
                  </Popover>
                )}

                {canEdit && (
                  <Popover
                    manualTrigger={true}
                    visible={isRemoveCarouselOpen}
                    onClickOutside={() => setIsRemoveCarouselOpen(false)}
                  >
                    <Template label='trigger'>
                      <Icon
                        icon='delete'
                        color='delete'
                        hoverColor='deleteLight'
                        onClick={() => setIsRemoveCarouselOpen(isRemoveCarouselOpen => !isRemoveCarouselOpen)}
                      />
                    </Template>
                    <Template label='popover'>
                      <DeleteConfirmation
                        message='Are you sure you want to remove this carousel?'
                        cancelButtonFunction={() => setIsRemoveCarouselOpen(false)}
                        proceedButtonFunction={async () => {
                          setIsRemoveCarouselOpen(false);
                          handleRemove();
                        }}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Cluster>
          </Cluster>
          <Carousel
            imageBorderRadius={imageBorderRadius}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            height={height}
            onItemClick={items.some(item => !!item.linkToRedirect) ? handleOnItemClick : undefined}
            truncateItemTextOnLine={truncateItemTextOnLine}
            items={tiles}
          />
        </Stack>
      </Box>
    );
  },
);

CarouselBlock.displayName = 'CarouselBlock';
export default CarouselBlock;
